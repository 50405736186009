import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import "../styles/Privacy.css";
export default function Privacy() {
  return (
    // <div style={{ margin: "100px", padding: "100px" }}>
    <div className="terms-and-conditions-container">
      <h1>
        Privacy Policy for ehobby.in - Owned by Intriped Innovation Pvt Ltd
      </h1>
      <p>
        Thank you for choosing ehobby.in, provided by Intriped Innovation Pvt
        Ltd, for your live online classes. Protecting your privacy and ensuring
        the security of your personal information is our top priority. This
        Privacy Policy outlines how we collect, use, and disclose your
        information when you use our website and services.
      </p>

      <h2>1. Information We Collect:</h2>
      <p>
        a. <strong>Personal Information:</strong> When you register or use our
        services, we may collect personal information such as your name, email
        address, phone number, and billing information.
      </p>
      <p>
        b. <strong>Usage Data:</strong> We may collect information about how you
        interact with our website and services, including your IP address,
        browser type, device information, and pages visited.
      </p>
      <p>
        c. <strong>Payment Information:</strong> If you make payments through
        our website, we may collect payment information such as credit card
        details, billing address, and transaction history.
      </p>

      <h2>2. How We Use Your Information:</h2>
      <p>
        a. <strong>To Provide Services:</strong> We use your information to
        deliver the services you request, such as scheduling and conducting
        online classes, processing payments, and providing customer support.
      </p>
      <p>
        b. <strong>Communications:</strong> We may use your contact information
        to send you important updates, newsletters, promotional offers, and
        other communications related to our services. You can opt-out of
        receiving these communications at any time.
      </p>
      <p>
        c. <strong>Analytics:</strong> We may use usage data to analyze trends,
        track user activity, and improve our website and services.
      </p>
      <p>
        d. <strong>Legal Compliance:</strong> We may use your information to
        comply with legal obligations, enforce our terms of service, and protect
        the rights and safety of our users.
      </p>

      {/* <!-- Add more sections using similar HTML structure --> */}

      <h2>8. Contact Us:</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        practices, please write to us at <b>hello@ehobby.in </b> or reach us at
        <b>+91 9432115091</b> or at our office located at : TITAS APRTMENT, F NO
        - 02, BL- A, TEGHARIA MAIN ROAD, Kolkata, West Bengal, India, 700059
      </p>

      <p>
        By using our website and services, you consent to the collection, use,
        and disclosure of your information as described in this Privacy Policy.
      </p>
    </div>
  );
}
