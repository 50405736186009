import { useEffect, useState } from "react"
import { Dialog, DialogTitle, Grid, Typography, Button, CircularProgress, Select, MenuItem, InputLabel, FormControl } from "@mui/material"
import { Container, classes } from "styles/AuthStyles"
import { MuiTelInput } from "mui-tel-input"
import { MuiOtpInput } from "mui-one-time-password-input"
import { useNavigate } from "react-router-dom"
import Input from "./Input"
import { useAuth } from "context/AuthContext"
import { createUser } from "context/dbActions/user"
import { fetchCourses } from "context/dbActions/course"
import axios from "axios"

const Auth = ({ open, setOpen }) => {
	const initialState = { firstName: "", lastName: "", phone: "", course: "" }
	const initialError = { firstName: false, lastName: false, phone: false, course: false, otp: false }

	const [formData, setFormData] = useState(initialState)
	const [error, setError] = useState(initialError)
	const [phone, setPhone] = useState("")
	const [OTP, setOTP] = useState("")
	const [loading, setLoading] = useState(false)
	const [otpSent, setOtpSent] = useState(false)

	const [confirmObj, setConfirmObj] = useState({})
	const { setupRecaptcha } = useAuth()
	const navigate = useNavigate()
	const [courses, setCourses] = useState([])

	useEffect(() => {
		if (!courses.length) {
			fetchData()
		}
	}, [courses.length])
	const fetchData = async () => {
		setCourses(await fetchCourses())
	}
	const handleClose = () => setOpen(false)
	const validate = () => {
		const errors = {
			firstName: !formData.firstName,
			lastName: !formData.lastName,
			course: !formData.course,
			phone: formData.phone.length < 13,
		}
		setError(errors)
		return !(errors.firstName || errors.lastName || errors.course || errors.phone)
	}

	const resetFormData = () => {
		setFormData(initialState)
		setPhone("")
		setOtpSent(false)
		setOTP("")
		handleClose()
	}
	const handleSubmit = async event => {
		event.preventDefault()
		// Validate
		if (!OTP) {
			return
		}
		setError(initialError)
		try {
			setLoading(true)
			await confirmObj.confirm(OTP)
			localStorage.setItem("user", JSON.stringify(formData))
			await createUser(formData)
			saveInForms(formData)
			resetFormData()
			navigate("/book-demo")
		} catch (error) {
			console.log(error.message)
			setError({ ...error, otp: true })
			setOTP("")
		}
		setLoading(false)
	}
	const makeRequest = url => {
		let response
		try {
			response = axios.post(url, null, null)
		} catch (e) {
			response = e
		}
		console.log(response)
	}

	const saveInForms = formData => {
		const { firstName, lastName, phone, course } = formData

		const id = process.env.REACT_APP_LEADS_FORM_ID
		const data = {
			"entry.193541620": `${firstName} ${lastName}`, // Full Name
			"entry.914733256": course, // Course
			"entry.1688634344": phone, // Phone
		}
		const formUrl = "https://docs.google.com/forms/d/" + id + "/formResponse"
		const queryString = require("query-string")
		const q = queryString.stringifyUrl({
			url: formUrl,
			query: data,
		})
		makeRequest(q)
	}
	const handleSendOTP = async _ => {
		setLoading(false)
		if (!validate()) {
			return
		}
		setError(initialError)
		try {
			setLoading(true)
			const response = await setupRecaptcha(phone)
			setConfirmObj(response)
			setOtpSent(true)
		} catch (error) {
			console.log(error.message)
		}
		setLoading(false)
	}
	const handleChange = e => {
		if (e.target) {
			setFormData({ ...formData, [e.target.name]: e.target.value })
			return
		}
		setPhone(e)
		setFormData({ ...formData, phone: e })
	}
	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<Container component="main" maxWidth="xs" className={classes.root}>
				<DialogTitle textAlign="center">Please enter phone number to verify and proceed</DialogTitle>
				<form className={classes.form} onSubmit={handleSubmit}>
					<Grid container spacing={1}>
						<Typography className={classes.label}>Student's Name</Typography>
						<Input error={error.firstName} name="firstName" label="First Name" type="text" onChange={handleChange} value={formData.firstName} autoFocus half disabled={otpSent} />
						<Input error={error.lastName} name="lastName" label="Last Name" type="text" onChange={handleChange} value={formData.lastName} half disabled={otpSent} />
						<Typography className={classes.label}>Select a course</Typography>
						<FormControl fullWidth className={classes.label}>
							<InputLabel id="select-label">Course</InputLabel>
							<Select error={error.course} labelId="select-label" name="course" value={formData.course} label="Course" onChange={handleChange}>
								{courses.length ? (
									courses.map(course => (
										<MenuItem key={course.id} value={course.name}>
											{course.name}
										</MenuItem>
									))
								) : (
									<MenuItem style={{ display: "flex", justifyContent: "center" }}>
										<CircularProgress />
									</MenuItem>
								)}
							</Select>
						</FormControl>
						<Typography className={classes.label}>Student's Phone Number</Typography>
						<MuiTelInput
							error={error.phone}
							defaultCountry="US"
							name="phone"
							required
							placeholder="Phone Number"
							onChange={handleChange}
							value={phone}
							className={classes.label}
							disabled={otpSent}
							inputProps={{ maxLength: 15 }}
						/>
						{!otpSent && <div id="recaptcha-container" />}
						{otpSent && (
							<>
								<Typography className={classes.label}>Enter OTP</Typography>
								<MuiOtpInput className={classes.OTP} value={OTP} onChange={setOTP} length={6} />
							</>
						)}
					</Grid>
					{error.otp && (
						<Typography color="error" textAlign="center">
							Please enter valid OTP
						</Typography>
					)}
					{otpSent ? (
						<Button type="submit" fullWidth startIcon={loading && <CircularProgress size="2rem" />} disabled={loading} variant="contained" color="success" className={classes.submit}>
							VERIFY OTP
						</Button>
					) : (
						<>
							<Button
								fullWidth
								startIcon={loading && <CircularProgress size="2rem" />}
								disabled={loading}
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={handleSendOTP}
							>
								SEND OTP
							</Button>
							<Button fullWidth variant="contained" color="error" className={classes.submit} onClick={handleClose}>
								CANCEL
							</Button>
						</>
					)}
				</form>
			</Container>
		</Dialog>
	)
}

export default Auth
