import { styled } from "@mui/material/styles"
import MuiContainer from "@mui/material/Container"

const PREFIX = "AdminDashboard"

export const classes = {
	root: `${PREFIX}-root`,
	form: `${PREFIX}-form`,
	label: `${PREFIX}-label`,
	OTP: `${PREFIX}-OTP`,
	submit: `${PREFIX}-submit`,
	container: `${PREFIX}-container`,
	toolbar: `${PREFIX}-toolbar`,
	logo: `${PREFIX}-logo`,
	buttonBox: `${PREFIX}-buttonBox`,
	link: `${PREFIX}-link`,
	primaryButton: `${PREFIX}-primaryButton`,
	navbarItems: `${PREFIX}-navbarItems`,
	hamburger: `${PREFIX}-hamburger`,
	leftNavbarGroup: `${PREFIX}-leftNavbarGroup`,
}

export const Container = styled(MuiContainer)(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: 20,
	},
	[`& .${classes.form}`]: {
		width: "100%",
	},
	[`& .${classes.label}`]: {
		width: "100%",
		margin: "4px 0px 0px 8px",
	},
	[`& .${classes.OTP}`]: {
		margin: "11px 0 0 8px",
	},
	[`& .${classes.submit}`]: {
		height: 43,
		margin: "11px 0 0 0",
	},
	[`& .${classes.container}`]: {
		margin: 0,
		maxWidth: "100%",
	},
	[`& .${classes.logo}`]: {
		marginRight: 16,
		display: "flex",
		fontWeight: 700,
		color: "inherit",
		textDecoration: "none",
		letterSpacing: 1,
	},
	[`& .${classes.buttonBox}`]: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		gap: 30,
	},
	[`& .${classes.link}`]: {
		fontSize: 16,
		color: "white",

		fontWeight: 700,
		margin: "16px 7px",
	},
	[`& .${classes.primaryButton}`]: {
		minWidth: 148,
		background: "#EE4646",
		height: 43,
		padding: "0 23px",
		boxShadow: "4px 4px 4px #BE9797",
		borderRadius: 40,
		color: "white",
		marginLeft: 40,
	},
	[`& .${classes.navbarItems}`]: {
		// display: "none",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	[`& .${classes.hamburger}`]: {
		display: "none",
	},
	[`& .${classes.leftNavbarGroup}`]: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: 10,
		height: "100%",
		backgroundColor: "black",
		color: "white",
		width: 300,
		position: "absolute",
		top: 0,
		left: 0,
        padding: 20,
        
	},
	[theme.breakpoints.down("sm")]: {
		[`&.${classes.root}`]: {
			padding: "35px 0px",
			margin: "10px 15px",
		},
		[`& .${classes.logo}`]: {
			marginRight: 0,
			width: "-webkit-fill-available",
			justifyContent: "center",
			margin: "0 20px",
		},
		[`& .${classes.toolbar}`]: {
			display: "flex",
			justifyContent: "space-between",
			width: "100%",
		},
		[`& .${classes.buttonBox}`]: {
			display: "none",
		},
		[`& .${classes.hamburger}`]: {
			display: "flex",
		},
		[`& .${classes.navbarItems}`]: {
			backgroundColor: "black",
			color: "white",
			position: "fixed",
			left: 0,
			top: 0,
			height: "100%",
			paddingTop: 48,
			overflow: "hidden",
			zIndex: 99999,
			transition: "0.2s",
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		[`& .${classes.primaryButton}`]: {
			height: 43,
			padding: "0 23px",
			boxShadow: "none",
			borderRadius: 5,
			marginLeft: 0,
			color: "white",
		},
		[`& .${classes.leftNavbarGroup}`]: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			gap: 10,
		},
		[`& .${classes.link}`]: {
			margin: "10px 0",
			color: "white",
		},
	},
}))
