import { Button } from "@mui/material";

export const PrimaryButton = ({ eventListener, text, style, ...others }) => {
  return (
    <Button
      style={{
        minWidth: 148,
        background: "#EE4646",
        height: 43,
        padding: "0 23px",
        boxShadow: "4px 4px 4px #BE9797",
        borderRadius: 8,
        color: "white",
        fontSize: 17,
        // marginLeft: 40,
        ...style,
      }}
      onClick={eventListener}
      {...others}
    >
      {text}
    </Button>
  );
};

export const PrimaryButton2 = ({ eventListener, text, ...others }) => {
  return (
    <Button
      style={{
        background: "#EE4646",
        height: 43,
        padding: "0 23px",
        boxShadow: "none",
        borderRadius: 5,
        marginLeft: 0,
        color: "white",
      }}
      onClick={eventListener}
      {...others}
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;
