import { styled } from "@mui/material/styles"
import MuiContainer from "@mui/material/Container"

const PREFIX = "Auth"

export const classes = {
	root: `${PREFIX}-root`,
	form: `${PREFIX}-form`,
	label: `${PREFIX}-label`,
	OTP: `${PREFIX}-OTP`,
	submit: `${PREFIX}-submit`,
}

export const Container = styled(MuiContainer)(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: 20,
	},
	[`& .${classes.form}`]: {
		width: "100%",
	},
	[`& .${classes.label}`]: {
		width: "100%",
		margin: "4px 0px 0px 8px",
	},
	[`& .${classes.OTP}`]: {
		margin: "11px 0 0 8px",
	},
	[`& .${classes.submit}`]: {
		height: 43,
		margin: "11px 0 0 0",
	},
	[theme.breakpoints.down("sm")]: {
		[`& .${classes.OTP}`]: {
			gap: 5,
		},
	},
}))
