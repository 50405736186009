import {
  Avatar,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Container, classes } from "../styles/SuccessPage";
import {
  DoneTwoTone,
  WhatsApp,
  FileCopyOutlined,
  NavigateNextOutlined,
  StarOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const SuccessPage = () => {
  const [copied, setCopied] = useState(false);
  const user = JSON.parse(localStorage.getItem("demo"));
  const date = moment(user.date, "MM-DD-YYYY");
  const course = user.course;
  const schedule = `${date.format("dddd MMMM Do, YYYY")} ${user.timeSlot}`;
  const navigate = useNavigate();
  const copyToClipBoard = () => {
    navigator.clipboard.writeText("https://ehobby.in/");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  const invite = () => {
    const text = `Hey! I just booked a Free Trial class with eHobby.in for ${course} at ${schedule} EST. Join this trial class with me and we can get special discounts while subscribing for full time classes! Click here to Register for the Trial class with me - https://ehobby.in`;

    window.open(`https://api.whatsapp.com/send?text=${text}`);
  };

  useEffect(() => {
    if (!user.course) {
      navigate("/");
    }
    if (!user.date) {
      navigate("/book-demo");
    }
  }, [user.course, user.date, navigate]);

  return (
    <Container className={classes.root} copied={`${copied}`}>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item sm={6} className={classes.leftGrid}>
          <div className={classes.textContainer}>
            <Typography
              variant="h4"
              gutterBottom
              fontWeight="bold"
              style={{ margin: "15px" }}
            >
              Invite your friend to join our FAM!
            </Typography>
            <Typography gutterBottom variant="body1" style={{ margin: "15px" }}>
              Invite your friends to join our hobby group and take advantage of
              special discounts by enrolling together. Help the community grow!
            </Typography>
            <Grid container spacing={2} className={classes.buttonGrid}>
              <Grid item sm={12} md={6} xs={12}>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<WhatsApp />}
                  className={classes.iconButton}
                  onClick={invite}
                  style={{ margin: "15px", padding: "10px" }}
                >
                  Invite on Whatsapp
                </Button>
              </Grid>
              <Grid item sm={12} md={6} xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<FileCopyOutlined />}
                  className={classes.iconButton}
                  onClick={copyToClipBoard}
                  style={{ margin: "15px", padding: "10px" }}
                >
                  {copied ? "Copied" : "Copy Invite Link"}
                </Button>
              </Grid>
            </Grid>
          </div>
          <hr style={{ marginLeft: "20px" }} />
          {/* <Divider className={classes.divider} style={{ margin: "15px" }} /> */}
          <ButtonBase
            style={{ margin: "15px" }}
            className={classes.buttonBase}
            onClick={() => navigate(`/dashboard`)}
          >
            <Paper className={classes.paperButton} elevation={7}>
              <StarOutlined className={classes.star} />
              <Typography> Proceed to the Dashboard</Typography>
              <Avatar className={classes.next}>
                <NavigateNextOutlined />
              </Avatar>
            </Paper>
          </ButtonBase>
        </Grid>
        <Grid item sm={6}>
          <Paper elevation={10} className={classes.successPaper}>
            <div className={classes.successTopDiv}>
              <Avatar className={classes.tickAvatar}>
                <DoneTwoTone className={classes.tick} />
              </Avatar>
              <Typography variant="h5" gutterBottom fontWeight="bold">
                Free Demo Booked!
              </Typography>
              <Typography paragraph variant="body2" gutterBottom align="center">
                Your child will be attending a 30 minutes {course} demo class on{" "}
                {schedule} EST
              </Typography>
            </div>
            <div className={classes.successBottomDiv}>
              <Typography
                variant="body2"
                fontWeight="bold"
                gutterBottom
                className={classes.underlinedText}
              >
                What's Next?
              </Typography>
              <Typography paragraph variant="body2" gutterBottom align="center">
                You wil be recieving the meeting link to join the demo class on
                the given email address. A representative will soon contact you
                via WhatsApp for further confirmation. We hope you join our
                hobby family after the demo!
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SuccessPage;
