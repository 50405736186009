import { styled } from "@mui/material/styles"
import MuiContainer from "@mui/material/Container"

const PREFIX = "SuccessPage"

export const classes = {
	root: `${PREFIX}-root`,
	grid: `${PREFIX}-grid`,
	star: `${PREFIX}-star`,
	next: `${PREFIX}-next`,
	tick: `${PREFIX}-tick`,
	leftGrid: `${PREFIX}-leftGrid`,
	textContainer: `${PREFIX}-textContainer`,
	buttonGrid: `${PREFIX}-buttonGrid`,
	iconButton: `${PREFIX}-iconButton`,
	divider: `${PREFIX}-divider`,
	buttonBase: `${PREFIX}-buttonBase`,
	paperButton: `${PREFIX}-papaerButton`,
	successPaper: `${PREFIX}-successPaper`,
	successTopDiv: `${PREFIX}-successTopDiv`,
	successBottomDiv: `${PREFIX}-successBottomDiv`,
	tickAvatar: `${PREFIX}-tickAvatar`,
	underlinedText: `${PREFIX}-underlinedText`,
}

export const Container = styled(MuiContainer)(({ theme, copied }) => ({
	[`&.${classes.root}`]: {
		margin: "170px auto",
		marginBottom: 40,
		padding: 30,
	},
	[`& .${classes.leftGrid}`]: {
		paddingRight: 110,
	},
	[`& .${classes.textContainer}`]: {
		paddingLeft: 10,
	},
	[`& .${classes.buttonGrid}`]: {
		marginBottom: 20,
		width: "100%",
	},
	[`& .${classes.iconButton}`]: {
		width: "100%",
	},
	[`& .${classes.divider}`]: {
		backgroundColor: "black",
	},
	[`& .${classes.buttonBase}`]: {
		marginTop: 24,
		borderRadius: 13,
	},
	[`& .${classes.star}`]: {
		color: "gold",
	},
	[`& .${classes.next}`]: {
		background: "none",
		color: "black",
		border: "1px solid black",
	},
	[`& .${classes.tick}`]: {
		width: 80,
		height: 80,
	},
	[`& .${classes.paperButton}`]: {
		textAlign: "left",
		gap: 9,
		padding: 10,
		borderRadius: "inherit",
		display: "flex",
		alignItems: "center",
		background: "none",
		border: "1px black solid",
	},
	[`& .${classes.successPaper}`]: {
		backgroundColor: "#ffffff17",
		backdropFilter: "blur(10px)",
		minHeight: 450,
		borderRadius: 30,
		position: "relative",
	},
	[`& .${classes.successTopDiv}`]: {
		alignItems: "center",
		flexDirection: "column",
		display: "flex",
		padding: 20,
		gap: 20,
	},
	[`& .${classes.successBottomDiv}`]: {
		backgroundColor: "#d9d9d9b3",
		borderRadius: "0 0 30px 30px",
		alignItems: "center",
		flexDirection: "column",
		display: "flex",
		padding: 20,
		position: "absolute",
		bottom: 0,
	},
	[`& .${classes.tickAvatar}`]: {
		width: 100,
		height: 100,
		backgroundColor: "limegreen",
	},
	[`& .${classes.underlinedText}`]: {
		textDecoration: "underline",
		textDecorationColor: "red",
	},
	[theme.breakpoints.down("lg")]: {
		[`& .${classes.leftGrid}`]: {
			paddingRight: 25,
		},
	},

	[theme.breakpoints.down("sm")]: {
		[`& .${classes.leftGrid}`]: {
			paddingRight: 0,
			marginTop: 20,
		},
		[`& .${classes.textContainer}`]: {
			textAlign: "center",
		},
		[`& .${classes.grid}`]: {
			flexDirection: "column-reverse",
		},
		[`& .${classes.successPaper}`]: {
			minHeight: 490,
		},
	},
}))
