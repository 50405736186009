import { styled } from "@mui/material/styles";
import MuiContainer from "@mui/material/Container";
import MuiBox from "@mui/material/Box";

const PREFIX = "Courses";

export const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  lineBreak: `${PREFIX}-lineBreak`,
  tabBox: `${PREFIX}-tabBox`,
  tabText: `${PREFIX}-tabText`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  avatar: `${PREFIX}-avatar`,
  title: `${PREFIX}-title`,
  titleText: `${PREFIX}-titleText`,
  tagLine: `${PREFIX}-tagLine`,
  actions: `${PREFIX}-actions`,
  divider: `${PREFIX}-divider`,
  buttons: `${PREFIX}-buttons`,
  media: `${PREFIX}-media`,
};

export const Container = styled(MuiContainer)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "60px auto",
    maxWidth: "100%",
  },
  [`& .${classes.heading}`]: {
    marginTop: "35px",
    textShadow: "#929292 2px 2px 7px",
    fontSize: 42,
    textAlign: "center",
  },
  [`& .${classes.tabBox}`]: {
    borderBottom: 1,
    borderColor: "divider",
    display: "flex",
  },
  [`& .${classes.tabText}`]: {
    fontSize: 20,
  },
  [theme.breakpoints.down("sm")]: {
    [`&.${classes.root}`]: {
      margin: "60px auto",
    },
    [`& .${classes.heading}`]: {
      fontSize: 39,
    },
    [`& .${classes.tabText}`]: {
      fontSize: 15,
    },
  },
}));

export const Box = styled(MuiBox)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: 10,
    gap: 100,
    display: "flex",
  },
  [`& .${classes.card}`]: {
    width: 800,
    borderRadius: 15,
  },
  [`& .${classes.cardContent}`]: {
    position: "relative",
  },
  [`& .${classes.titleText}`]: {
    fontSize: 32,
  },
  [`& .${classes.title}`]: {
    marginTop: 35,
    color: "#686e82",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [`& .${classes.tagLine}`]: {
    marginBottom: 35,
    color: "#686e82",
    fontSize: 18,
  },
  [`& .${classes.actions}`]: {
    display: "flex",
    justifyContent: "center",
  },
  [`& .${classes.divider}`]: {
    backgroundColor: "#686e82",
  },
  [`& .${classes.buttons}`]: {
    backgroundColor: "#DEFFC3",
    color: "#686e82",
    borderColor: "#686e82",
    width: 130,
  },
  [theme.breakpoints.down("sm")]: {
    [`&.${classes.root}`]: {
      flexDirection: "column",
      gap: 30,
    },
    [`& .${classes.card}`]: {
      width: 300,
    },
    [`& .${classes.media}`]: {
      height: 250,
    },
    [`& .${classes.buttons}`]: {
      width: "auto",
    },
  },
  [theme.breakpoints.down(1600)]: {
    [`&.${classes.root}`]: {
      flexDirection: "column",
      gap: 15,
    },
    [`& .${classes.card}`]: {
      margin: "0px",
      width: 330,
      height: 450,
    },
    [`& .${classes.media}`]: {
      height: 150,
    },
    [`& .${classes.buttons}`]: {
      width: "auto",
      fontSize: 14,
    },
    [`& .${classes.titleText}`]: {
      fontSize: 30,
    },
    [`& .${classes.title}`]: {
      marginTop: 2,
    },
  },
}));
