import { useCallback, useEffect, useMemo, useState } from "react"
import { Dialog, DialogTitle, Grid, Typography, Button, CircularProgress, Select, MenuItem, InputLabel, FormControl } from "@mui/material"
import { Container, classes } from "styles/AuthStyles"
import { MuiTelInput } from "mui-tel-input"
import { MuiOtpInput } from "mui-one-time-password-input"
import { useNavigate } from "react-router-dom"
import Input from "./Input"
import { useAuth } from "context/AuthContext"
import { fetchCourses } from "context/dbActions/course"
import { createUser, fetchUser, isExistingUser } from "context/dbActions/user"

const Login = ({ open, setOpen }) => {
	const initialState = { firstName: "", lastName: "", phone: "", course: "" }
	const initialError = useMemo(() => ({ firstName: false, lastName: false, phone: false, course: false, otp: false }), [])
	const [existingUser, setExistingUser] = useState(false)
	const [formData, setFormData] = useState(initialState)
	const [checked, setChecked] = useState(false)
	const [error, setError] = useState(initialError)
	const [phone, setPhone] = useState("")
	const [OTP, setOTP] = useState("")
	const [loading, setLoading] = useState(false)
	const [otpSent, setOtpSent] = useState(false)
	const [confirmObj, setConfirmObj] = useState({})
	const { setupRecaptcha } = useAuth()
	const navigate = useNavigate()
	const [courses, setCourses] = useState([])
	useEffect(() => {
		return async () => setCourses(await fetchCourses())
	}, [])
	const handleClose = () => setOpen(false)
	const validate = useCallback(() => {
		const errors = {
			phone: formData.phone.length < 13,
		}
		setError(errors)
		return !errors.phone
	}, [formData.phone])

		const resetFormData = () => {
			setFormData(initialState)
			setPhone("")
			setOtpSent(false)
			setOTP("")
			handleClose()
		}
	const handleSubmit = async event => {
		event.preventDefault()
		// Validate
		if (!OTP) {
			return
		}
		setError(initialError)
		try {
			setLoading(true)
			await confirmObj.confirm(OTP)
			if (!existingUser) {
				await createUser(formData)
			}
			const data = await fetchUser(formData.phone)
			localStorage.setItem("user", JSON.stringify(data))
			resetFormData()
			navigate("/dashboard")
		} catch (error) {
			console.log(error.message)
			setError({ ...error, otp: true })
			setOTP("")
		}
		setLoading(false)
	}

	const handleSendOTP = useCallback(
		async e => {
			e?.preventDefault()
			setLoading(false)
			if (!validate()) {
				return
			}
			setError(initialError)
			try {
				setLoading(true)
				const response = await setupRecaptcha(phone)
				setConfirmObj(response)
				setOtpSent(true)
			} catch (error) {
				console.error(error.message)
			}
			setLoading(false)
		},
		[initialError, phone, setupRecaptcha, validate]
	)
	const handleChange = e => {
		if (e.target) {
			setFormData({ ...formData, [e.target.name]: e.target.value })
			return
		}
		setPhone(e)
		setFormData({ ...formData, phone: e })
	}
	const checkExisingUser = async e => {
		e.preventDefault()
		setExistingUser(await isExistingUser(phone))
		setChecked(true)
	}
	useEffect(() => {
		if (existingUser) {
			handleSendOTP()
		}
	}, [existingUser, handleSendOTP])
	
	return (
		<Dialog open={open} onClose={handleClose}>
			<Container component="main" maxWidth="xs" className={classes.root}>
				{!otpSent ? (
					<>
						<DialogTitle textAlign="center">Please enter phone number to verify and proceed</DialogTitle>
						<form className={classes.form} onSubmit={checked ? handleSendOTP : checkExisingUser}>
							<Grid container spacing={1}>
								<Typography className={classes.label}>Phone Number</Typography>
								<MuiTelInput
									inputProps={{ maxLength: 15 }}
									error={error.phone}
									defaultCountry="US"
									name="phone"
									required
									placeholder="Phone Number"
									onChange={handleChange}
									value={phone}
									className={classes.label}
								/>
							</Grid>
							{checked &&
								(existingUser ? (
									<div id="recaptcha-container" />
								) : (
									<Grid container spacing={1}>
										<Typography className={classes.label}>Student's Name</Typography>
										<Input error={error.firstName} name="firstName" label="First Name" type="text" onChange={handleChange} value={formData.firstName} autoFocus half />
										<Input error={error.lastName} name="lastName" label="Last Name" type="text" onChange={handleChange} value={formData.lastName} half disabled={otpSent} />
										<Typography className={classes.label}>Select a course</Typography>
										<FormControl fullWidth className={classes.label}>
											<InputLabel id="select-label">Course</InputLabel>
											<Select error={error.course} labelId="select-label" name="course" value={formData.course} label="Course" onChange={handleChange}>
												{courses.length ? (
													courses.map(course => (
														<MenuItem key={course.id} value={course.name}>
															{course.name}
														</MenuItem>
													))
												) : (
													<MenuItem style={{ display: "flex", justifyContent: "center" }}>
														<CircularProgress textAlign="center" />
													</MenuItem>
												)}
											</Select>
										</FormControl>
										<Typography textAlign="center" gutterBottom sx={{ width: "100%" }} color="error">
											You are not signed in with us. Please fill the following details to continue.
										</Typography>
										{checked && <div id="recaptcha-container" />}
									</Grid>
								))}
							<Button fullWidth startIcon={loading && <CircularProgress size="2rem" />} disabled={loading} variant="contained" color="primary" className={classes.submit} type="submit">
								LOG IN
							</Button>
							<Button fullWidth variant="contained" color="error" className={classes.submit} onClick={handleClose}>
								CANCEL
							</Button>
						</form>
					</>
				) : (
					<>
						<DialogTitle textAlign="center">Please enter phone number to verify and proceed</DialogTitle>
						<form className={classes.form} onSubmit={handleSubmit}>
							<Grid container spacing={1}>
								<Typography className={classes.label}>Phone Number</Typography>
								<MuiTelInput value={phone} className={classes.label} disabled />
								<Typography className={classes.label}>Enter OTP</Typography>
								<MuiOtpInput className={classes.OTP} value={OTP} onChange={setOTP} length={6} />
							</Grid>
							{error.otp && (
								<Typography color="error" textAlign="center">
									Please enter valid OTP
								</Typography>
							)}
							<Button type="submit" fullWidth startIcon={loading && <CircularProgress size="2rem" />} disabled={loading} variant="contained" color="success" className={classes.submit}>
								VERIFY OTP
							</Button>
						</form>
					</>
				)}
			</Container>
		</Dialog>
	)
}

export default Login
