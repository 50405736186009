// CRUD FUNCTIONS FOR Mentors in Firestore
import { doc, setDoc, serverTimestamp, updateDoc,getDoc, collection, getDocs, deleteDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { createSchedule, deleteSchedule } from "./schedule"

export const createMentor = async ({ id, phone, name, teaches }) => {
	const documentRef = doc(db, "mentors", id)
	const snap = await getDoc(documentRef)
	if (snap.exists()) {
		return
	}
	const payload = {
		name,
		phone,
		teaches,
		joined: serverTimestamp(),
	}
	await setDoc(documentRef, payload)
	await createSchedule(id, teaches)
}
export const fetchMentors = async () => {
	const result = []
	const snap = await getDocs(collection(db, "mentors"))
	snap.forEach(mentor => result.push({ id: mentor.id, ...mentor.data() }))
	return result
}
export const fetchMentor = async id => {
	const documentRef = doc(db, "mentors", id)
	const snap = await getDoc(documentRef)
	return { ...snap.data(), id: snap.id }
}
export const updateMentor = async (id, data) => {
	const documentRef = doc(db, "mentors", id)
	delete data.id
	await updateDoc(documentRef, data)
}

export const deleteMentor = async id => {
	await deleteSchedule(id)
	await deleteDoc(doc(db, "mentors", id))
}
