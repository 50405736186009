import { DialogTitle, Button } from "@mui/material"
import { Container, classes } from "styles/Admin/DashboardStyles"
import { useAuth } from "context/AuthContext"

const Dashboard = () => {
	const { logOut } = useAuth()
	return (
		<Container component="main" maxWidth="xs" className={classes.root}>
			<DialogTitle textAlign="center">Dashboard</DialogTitle>
			<Button fullWidth variant="contained" color="primary" onClick={logOut}>
				LOG OUT
			</Button>
		</Container>
	)
}

export default Dashboard
