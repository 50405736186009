import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import "../../styles/Terms.css";

export default function Terms() {
  return (
    // <div style={{ margin: "100px", padding: "100px" }}>
    <div className="terms-and-conditions-container">
      <h1>Terms and Conditions</h1>
      <h2>CODE OF CONDUCT FOR eHobby USERS</h2>

      <p>
        eHobby is committed to fostering a positive and conducive learning
        environment for all users on its online platform. We expect every
        participant to adhere to the following guidelines to promote respectful
        and effective engagement.
      </p>
      <ol>
        <li>
          Show respect to fellow eHobby users, instructors, and our support
          team. This involves refraining from the use of discriminatory
          language, harassment, or any other behavior that may be perceived as
          disrespectful.
        </li>
        <li>
          Take responsibility for your actions and commitments as an eHobby
          user. This includes regular class attendance and timely completion of
          assignments. Additionally, users must comply with the specified
          policies regarding class cancellation and rescheduling to maintain
          consistent learning outcomes.
        </li>
        <li>
          Avoid engaging in any behavior that could disrupt the learning
          environment or hinder the progress.
        </li>
      </ol>

      <h2>CLASS CANCELLATION AND RESCHEDULING POLICY FOR eHobby USERS</h2>
      <p>
        In order to ensure an optimal learning experience, eHobby recommends
        that users make every effort to attend scheduled classes.
      </p>
      <ol>
        <li>
          The mentor will wait for a maximum of 10 minutes. If a student is
          unable to join the class within this timeframe for any reason, the
          class will be considered canceled. No rescheduling options will be
          available, and requests made after the mentor has left the class, even
          beyond the 11th minute, will not be entertained.
        </li>
        <li>
          Users are allowed a maximum of 1 session cancellation or rescheduling
          per every 8 sessions within the payment cycle.
        </li>
        <li>
          Cancellations or rescheduling requests must be communicated
          exclusively through the WhatsApp communication channel by text,
          notifying the Coordinators at least 12 hours before the scheduled
          class time. No cancellations or rescheduling will be permitted after
          the mentor joins the class.
        </li>
        <li>
          Once the maximum limit for cancellations or rescheduling is reached,
          no further changes will be allowed.
        </li>
        <li>
          If a student fails to join a class without prior cancellation, it will
          be considered a "Student No Show" and no rescheduling option will be
          provided.
        </li>
      </ol>

      <h2>PAYMENT AND REFUND POLICY FOR eHobby USERS:</h2>
      <ol>
        <li>
          Payments must be made exclusively to the account details shared by our
          coordinators through either email or the WhatsApp communication
          channel. A screenshot of the transaction should be provided before the
          commencement of each payment cycle; failure to do so may result in the
          non-scheduling of classes.
        </li>
        <li>
          Payments are collected in advance for either 4 classes (for
          once-a-week sessions over a month) or 8 classes (for twice-a-week
          sessions over a month).
        </li>
        <li>
          Upon completion of the payment cycle, a notification will be sent to
          the student to facilitate payment for the next cycle. Failure to make
          the payment within one week will result in the release of assigned
          slots, which may be allocated to other users.
        </li>
        <li>
          eHobby has a strict no-refund policy. Since a free demo is provided,
          and payment is accepted only for 4 or 8 classes, users are urged to
          ensure their interest and commitment before enrolling in eHobby
          classes.
        </li>
      </ol>
    </div>
  );
}
