import { classes, AppBar } from "styles/NavbarStyles";
import {
  Box,
  Toolbar,
  Container,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAuth } from "context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryButton, PrimaryButton2 } from "./PrimaryButton";
import { SecondaryButton, SecondaryButton2 } from "./SecondaryButton";
import adminAvatar from "assets/admin.png";
import logo from "../assets/logo-1.png";

const Navbar = ({ bookDemo, openLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const { currentUser, logOut } = useAuth();
  const handleLogout = async () => {
    try {
      await logOut();
    } catch {
      console.log("error");
    }
  };
  const [backgroundColor, setBackgroundColor] = useState(false);
  useEffect(() => {
    const toggleColor = () => setBackgroundColor(window.pageYOffset > 30);
    window.addEventListener("scroll", toggleColor);
    return () => window.removeEventListener("scroll", toggleColor);
  }, []);

  const isInDashboard = location.pathname.startsWith("/admin/dashboard");
  const isAdmin = !currentUser?.phoneNumber;
  return isInDashboard ? null : (
    <>
      <AppBar className={classes.root} background={`${backgroundColor}`}>
        <div
          className={classes.background}
          onClick={() => setShow(false)}
          style={{ display: show ? "flex" : "none" }}
        />
        <Container className={classes.container}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Menu
              className={classes.hamburger}
              onClick={() => setShow((prev) => !prev)}
            />
            <div>
              <Box
                className={classes.navbarItems}
                style={{ width: show ? "50%" : 0 }}
              >
                <div className={classes.leftNavbarGroup}>
                  <Menu onClick={() => setShow((prev) => !prev)} />
                  {currentUser ? (
                    isAdmin ? (
                      <IconButton
                        children={<Avatar src={adminAvatar} />}
                        onClick={() => navigate("admin/dashboard")}
                      />
                    ) : (
                      <IconButton
                        children={<Avatar />}
                        onClick={() => navigate("/dashboard")}
                      />
                    )
                  ) : null}
                  {location.pathname === "/" && (
                    <PrimaryButton2
                      onClick={() => bookDemo(navigate)}
                      text="BOOK A DEMO!"
                      style={{
                        backgroundColor: " #FFB343",
                        fontSize: 15,
                        boxShadow: "4px 2.5px 2.5px #aeaeae",
                        color: "white",
                      }}
                    />
                  )}
                  {currentUser ? (
                    <SecondaryButton2 onClick={handleLogout} text="LOGOUT" />
                  ) : (
                    <SecondaryButton2
                      onClick={() => openLogin(true)}
                      text="LOGIN"
                    />
                  )}
                </div>
                <div></div>
              </Box>
            </div>
            {currentUser ? (
              isAdmin ? (
                <IconButton
                  className={classes.desktopAvatar}
                  children={<Avatar src={adminAvatar} />}
                  onClick={() => navigate("admin/dashboard")}
                />
              ) : (
                <IconButton
                  className={classes.desktopAvatar}
                  children={<Avatar />}
                  onClick={() => navigate("/dashboard")}
                />
              )
            ) : null}
            <Typography
              className={classes.logo}
              variant="h6"
              noWrap
              component="a"
              href="/"
            >
              <div>
                <img src={logo} />
              </div>
            </Typography>
            <Box className={classes.buttonBox}>
              {location.pathname === "/" && (
                <PrimaryButton
                  eventListener={() => bookDemo(navigate)}
                  text="BOOK A DEMO!"
                  style={{
                    backgroundColor: " #FFB343",
                    fontSize: 15,
                    boxShadow: "4px 2.5px 2.5px #aeaeae",
                  }}
                />
              )}
              {currentUser ? (
                <SecondaryButton onClick={handleLogout} text="LOGOUT" />
              ) : (
                <SecondaryButton onClick={() => openLogin(true)} text="LOGIN" />
              )}
            </Box>
            {currentUser ? (
              isAdmin ? (
                <IconButton
                  className={classes.mobileAvatar}
                  children={<Avatar src={adminAvatar} />}
                  onClick={() => navigate("admin/dashboard")}
                />
              ) : (
                <IconButton
                  className={classes.mobileAvatar}
                  children={<Avatar />}
                  onClick={() => navigate("/dashboard")}
                />
              )
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default Navbar;
