import { styled } from "@mui/material/styles"

const PREFIX = "Sidebar"

export const classes = {
	root: `${PREFIX}-root`,
	list: `${PREFIX}-list`,
	row: `${PREFIX}-row`,
	icon: `${PREFIX}-icon`,
	title: `${PREFIX}-title`,
	logo: `${PREFIX}-logo`,
}

export const Container = styled("div")(({ theme }) => ({
	[`&.${classes.root}`]: {
		zIndex: 999,
		height: "100%",
		width: 250,
		backgroundColor: "#2F4050",
		position: "absolute",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		top: 0,
		paddingTop: 10,
	},
	[`& .${classes.list}`]: {
		height: "auto",
		padding: 0,
		width: "100%",
	},
	[`& .${classes.logo}`]: {
		marginRight: 16,
		display: "flex",
		fontWeight: 700,
		textDecoration: "none",
		letterSpacing: 1,
		color: "white",
	},
	[`& .${classes.row}`]: {
		width: "100%",
		height: 60,
		listStyleType: "none",
		margin: 0,
		display: "flex",
		flexDirection: "row",
		color: "white",
		justifyContent: "center",
		alignItems: "center",
		fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
	},
	[`& .${classes.row}:hover`]: {
		backgroundColor: "#293846",
		cursor: "pointer",
	},
	[`& .${classes.icon}`]: {
		flex: "30%",
		display: "grid",
		placeItems: "center",
	},
	[`& .${classes.title}`]: {
		flex: "70%",
	},
	[`&.${classes.root} #active`]: {
		backgroundColor: "white",
		color: "#2F4050",
		borderRadius: 5,
		margin: 15,
		width: 230,
		boxShadow: "3px 3px 6px 1px black",
		transition: ".3s ease-in-out",
	},
}))
