import { styled } from "@mui/material/styles"
import MuiContainer from "@mui/material/Container"

const PREFIX = "Mentors"

export const classes = {
	root: `${PREFIX}-root`,
	header: `${PREFIX}-header`,
	text: `${PREFIX}-text`,
	subHeader: `${PREFIX}-subHeader`,
	paragraph: `${PREFIX}-paragraph`,
	point: `${PREFIX}-point`,
	points: `${PREFIX}-points`,
	image: `${PREFIX}-image`,
	checkpoint: `${PREFIX}-checkpoint`,
}

export const Container = styled(MuiContainer)(({ theme }) => ({
	[`&.${classes.root}`]: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		maxWidth: "100%",
		width: "100%",
		padding: "29px 40px",
		margin: "90px 0",
		gap: 30,
	},
	[`& .${classes.header}`]: {
		flexDirection: "column",
		alignItems: "center",
		marginBottom: 70,
	},
	[`& .${classes.paragraph}`]: {
		fontSize: 22,
		color: "#acacac",
	},
	[`& .${classes.text}`]: {
		textShadow: "#929292 2px 2px 7px",
		fontSize: 42,
		textAlign: "center",
		// margin:'100px 0'
	},
	[`& .${classes.subHeader}`]: {
		fontSize: 22,
		letterSpacing: 2,
		color: "#acacac",
		fontWeight: 600,
	},
	[`& .${classes.image}`]: {
		width: 600,
	},
	[`& .${classes.points}`]: {
		display: "flex",
		flexDirection: "column",
		margin: 30,
		gap: 20,
	},
	[`& .${classes.checkpoint}`]: {
		display: "flex",
		gap: 10,
		alignItems: "center",
	},
	[`& .${classes.point}`]: {
		fontSize: 20,
	},
	[theme.breakpoints.down("md")]: {
		[`& .${classes.image}`]: {
			width: "80%",
		},
		[`&.${classes.root}`]: {
			height: "auto",
		},
	},
	[theme.breakpoints.down("sm")]: {
		[`&.${classes.root}`]: {
			textAlign: "center",
			height: "auto",
			padding: "28px 20px",
			margin: "100px 0",
		},
		[`& .${classes.pointText}`]: {
			textAlign: "center",
		},
		[`& .${classes.text}`]: {
			fontSize: 39,
		},
		[`& .${classes.image}`]: {
			marginLeft: 30,
			marginTop: 70,
		},
		[`& .${classes.subHeader}`]: {
			fontSize: 23,
			fontWeight: 300,
		},
		[`& .${classes.paragraph}`]: {
			fontSize: 23,
		},
		[`& .${classes.point}`]: {
			alignItems: "flex-start",
			fontSize: 18,
		},
		[`& .${classes.points}`]: {
			textAlign: "left",
		},
	},
}))
