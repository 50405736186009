import { Tab, Tabs, Typography, Grid } from "@mui/material";
import { useState } from "react";
import { classes, Container, Box } from "styles/CoursesStyles";
import courses from "../../data/courses.json";
import { TabPanel } from "components/TabPanel";

const Courses = ({ bookDemo }) => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => setValue(newValue);

  return (
    <Container className={classes.root}>
      <Typography className={classes.heading}>
        An array of courses available for selection.
      </Typography>
      <br />
      <Box className={classes.tabBox} style={{ marginBottom: "40px" }}>
        <br />
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Music" className={classes.tabText} />
          <Tab label="Fitness" className={classes.tabText} />
          <Tab label="Academic" className={classes.tabText} />
          {/* <Tab label="More" className={classes.tabText} /> */}
        </Tabs>
      </Box>
      <TabPanel
        bookDemo={bookDemo}
        value={value}
        index={0}
        dataArr={courses.music}
      />
      <TabPanel
        bookDemo={bookDemo}
        value={value}
        index={1}
        dataArr={courses.fitness}
      />
      <TabPanel
        bookDemo={bookDemo}
        value={value}
        index={2}
        dataArr={courses.coding}
      />
    </Container>
  );
};

export default Courses;
