import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

const PREFIX = "Navbar";

export const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  toolbar: `${PREFIX}-toolbar`,
  logo: `${PREFIX}-logo`,
  buttonBox: `${PREFIX}-buttonBox`,
  link: `${PREFIX}-link`,
  primaryButton: `${PREFIX}-primaryButton`,
  navbarItems: `${PREFIX}-navbarItems`,
  hamburger: `${PREFIX}-hamburger`,
  leftNavbarGroup: `${PREFIX}-leftNavbarGroup`,
  mobileAvatar: `${PREFIX}-mobileAvatar`,
  desktopAvatar: `${PREFIX}-desktopAvatar`,
  background: `${PREFIX}-background`,
};

export const AppBar = styled(MuiAppBar)(({ theme, background }) => ({
  [`&.${classes.root}`]: {
    margin: "50px 70px",
    padding: "35px 0px 35px 60px",
    display: "flex",
    backgroundColor: background === "true" ? "#f7fafd" : "#f7fafd",
    color: "black",
    boxShadow: background === "true" ? "  white 2px 2px 3.5px" : "none",
    position: "fixed",
    width: "-webkit-fill-available",
    height: 50,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${classes.background}`]: {
    width: "100%",
    height: 5000,
    backgroundColor: "#00000054",
    position: "fixed",
    zIndex: 999,
    // display: "none",
  },
  [`& .${classes.container}`]: {
    margin: 0,
    maxWidth: "100%",
  },
  [`& .${classes.logo}`]: {
    marginRight: 16,
    display: "flex",
    fontWeight: 700,
    color: "inherit",
    textDecoration: "none",
    letterSpacing: 1,
  },
  [`& .${classes.buttonBox}`]: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 30,
  },
  [`& .${classes.link}`]: {
    fontSize: 14,
    color: "black",
    fontWeight: 700,
    margin: "16px 7px",
  },
  [`& .${classes.primaryButton}`]: {
    minWidth: 148,
    background: "#fffff",
    height: 43,
    padding: "0 23px",
    boxShadow: "2px 2px 2px black",
    borderRadius: 50,
    color: "white",
    marginLeft: 40,
  },
  [`& .${classes.navbarItems}`]: {
    display: "none",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [`& .${classes.mobileAvatar}`]: {
    display: "none",
    // marginRight: 20,
  },
  [`& .${classes.hamburger}`]: {
    display: "none",
  },
  [`& .${classes.desktopAvatar}`]: {
    display: "block",
  },
  [theme.breakpoints.down("sm")]: {
    [`& .${classes.desktopAvatar}`]: {
      display: "none",
    },
    [`& .${classes.mobileAvatar}`]: {
      display: "block",
    },
    [`&.${classes.root}`]: {
      padding: "35px 0px",
      margin: "10px 15px",
    },
    [`& .${classes.logo}`]: {
      marginRight: 0,
      width: "-webkit-fill-available",
      justifyContent: "center",
      margin: "0 20px",
    },
    [`& .${classes.toolbar}`]: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    [`& .${classes.buttonBox}`]: {
      display: "none",
    },
    [`& .${classes.hamburger}`]: {
      display: "flex",
    },
    [`& .${classes.navbarItems}`]: {
      backgroundColor: "black",
      color: "white",
      position: "fixed",
      left: 0,
      top: 0,
      height: "100%",
      paddingTop: 48,
      overflow: "hidden",
      zIndex: 99999,
      transition: "0.2s",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    [`& .${classes.primaryButton}`]: {
      height: 43,
      padding: "0 23px",
      boxShadow: "none",
      borderRadius: 5,
      marginLeft: 0,
      color: "white",
    },
    [`& .${classes.leftNavbarGroup}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 10,
    },
    [`& .${classes.link}`]: {
      margin: "10px 0",
      color: "white",
    },
  },
}));
