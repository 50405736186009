// import { Grid, Typography } from "@mui/material";
// import { Container, classes } from "styles/ParentsVoice";
// import Yoga from "../../assets/Test.png";

// const ParentsVoice = () => {
//   return (
//     <Container className={classes.root}>
//       <Typography className={classes.text} gutterBottom>
//         Parent's Voice
//       </Typography>
//       <Grid container spacing={3} className={classes.gridContainer}>
//         <Grid item xs={10} sm={10} md={12} lg={3} className={classes.gridItem}>
//           <div
//             className={classes.item}
//             // style={{ backgroundImage: `url(${Yoga})` }}
//           >
//             <img src={Yoga} />
//             <div>
//               {" "}
//               {/* "I have thoroughly enjoyed my online yoga classes at ehobby. The
//               teachers are top-notch and the platform is user-friendly. Highly
//               recommend!" */}
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={10} sm={10} md={6} lg={3} className={classes.gridItem}>
//           <div className={classes.item} />
//         </Grid>
//         <Grid item xs={10} sm={10} md={6} lg={3} className={classes.gridItem}>
//           <div className={classes.item} />
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default ParentsVoice;

import React from "react";
import "../../App.css";

export default function Testimonials() {
  return (
    <div>
      <div className="cards-2">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                fontFamily: "Poppins",
                margin: "10px",
                padding: "0px",
                fontSize: "30px",
              }}
            >
              <h2>Why parents trust us ❤️ </h2>
            </div>{" "}
          </div>{" "}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-image">
                  ⭐⭐⭐⭐⭐
                  <hr className="testimonial-line" />
                </div>
                <div className="card-body">
                  <div
                    className="testimonial-text"
                    style={{ fontFamily: "Poppins", margin: "10px" }}
                  >
                    "I would like to say thank you so much for getting me the
                    best teacher whose knowledge, experience and method of
                    teaching music is just out of the world. and Rick sir is
                    such nice human being also. I am really privileged to learn
                    from the best and credit goes to you eHobby team."
                  </div>
                  <div
                    className="testimonial-author"
                    style={{ fontFamily: "Poppins", margin: "10px" }}
                  >
                    Virag Kumar Patel, Johannesburg, South Africa{" "}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-image">
                  ⭐⭐⭐⭐⭐
                  <hr className="testimonial-line" />
                </div>
                <div
                  className="card-body"
                  style={{ fontFamily: "poppins", margin: "10px" }}
                >
                  <div className="testimonial-text">
                    "Ananya is enjoying her piano lessons, we are seeing lots of
                    progress day by day. I appreciate Joy sir for his patience
                    and he is very accommodating and professional. I understand
                    its not so easy to teach music online, but you guys are
                    doing a great job. Thank you."
                  </div>
                  <div className="testimonial-author">
                    Ramya Raghunandan, USA
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  ⭐⭐⭐⭐⭐
                  <hr className="testimonial-line" />
                </div>
                <div
                  className="card-body"
                  style={{ fontFamily: "poppins", margin: "10px" }}
                >
                  <div className="testimonial-text">
                    "My son is taking Java Classes from ehobby since last 5
                    months. My son is very happy learning Java and will always
                    look forward to attend the class. I have never seen him
                    taking so much interest in any other classes till now. I am
                    very satisfied with our experience with ehobby and would
                    highly recommend students to join ehobby."
                  </div>
                  <div className="testimonial-author">
                    Ayaan's mom, Boca Raton, Florida
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
