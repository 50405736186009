import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Container, classes } from "styles/DashboardStyles";
import { fetchUser } from "context/dbActions/user";
import { fetchClassesByUser } from "context/dbActions/class";
import { fetchDemosByUser } from "context/dbActions/demo";
import { useAuth } from "context/AuthContext";
import { Link } from "react-router-dom";
import { Verified } from "@mui/icons-material";

const Dashboard = () => {
  const { currentUser } = useAuth();
  const phone = currentUser.phoneNumber;
  const [data, setData] = useState({});
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [demos, setDemos] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserDetails = useCallback(async () => {
    setLoading(true);
    const data = await fetchUser(phone);
    const upcommingDemos = await fetchDemosByUser(phone);
    const upcomingClasses = await fetchClassesByUser(phone);
    setData(data);
    setUpcomingClasses(upcomingClasses);
    setDemos(upcommingDemos);
    setLoading(false);
  }, [phone]);

  useEffect(() => {
    getUserDetails();
  }, [phone, getUserDetails]);

  return (
    <Container className={classes.root}>
      {loading ? (
        <Paper elevation={6} component="main" className={classes.paper}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size="5rem" />
          </div>
        </Paper>
      ) : (
        <Paper elevation={6} component="main" className={classes.paper}>
          <Container className={classes.headerContainer}>
            <Typography variant="h4" color="error" gutterBottom>
              <strong>Hello,</strong> {data.name?.split(" ")[0]}!
            </Typography>
            <Typography variant="h6" fontWeight="light" color="secondary">
              Glad to have you back!
              <br />
              What an exciting day to continue learning!
            </Typography>
          </Container>
          <Container className={classes.bodyContainer}>
            <Grid container className={classes.topGrid}>
              <Grid
                item
                md={5.5}
                xs={12}
                style={{ display: "flex", flexDirection: "column", gap: 15 }}
              >
                <Typography variant="h5" fontWeight="bold">
                  Student info
                </Typography>
                <Paper className={classes.studentInfo} elevation={6}>
                  <Typography gutterBottom>
                    <strong>Name:</strong> {data.name}
                  </Typography>
                  <Typography gutterBottom>
                    <strong>Phone Number:</strong> {phone}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ overflowWrap: "break-word" }}
                  >
                    <strong>Email:</strong> {data.email}
                  </Typography>
                  <Typography gutterBottom>
                    <strong>Demos Booked till Date:</strong>{" "}
                    {data.demoReferences?.length}
                  </Typography>
                  <Typography gutterBottom>
                    <strong>Class Slots Enrolled till Date:</strong>{" "}
                    {data.classReferences?.length}
                  </Typography>
                </Paper>
                <Button
                  className={classes.bookDemo}
                  component="a"
                  href="/book-demo"
                >
                  Book A Demo
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={5.5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                  justifyContent: "center",
                }}
              >
                <Typography variant="h5" fontWeight="bold">
                  Upcoming demos :
                </Typography>
                {demos.filter((demo) => !demo.classReference).length ? (
                  demos
                    .filter((demo) => !demo.classReference)
                    .map((demo) => <Demo key={demo.id} demo={demo} />)
                ) : (
                  <Typography variant="h5" textAlign="center">
                    No upcoming demos ☹️
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.bottomGrid}>
              {/* <Grid item md={4} sm={12} xs={12}>
								<Typography variant="h5" fontWeight="bold" textAlign="center" gutterBottom>
									Our Couses
								</Typography>
								<Paper className={classes.courses} elevation={6}>
									{[...Array(3).keys()].map(key => (
										<Paper key={key} className={classes.course} />
									))}
								</Paper>
							</Grid> */}
              <Grid item md={6} xs={12}>
                <Typography variant="h5" fontWeight="bold" textAlign="center">
                  Upcoming Classes
                </Typography>
                {upcomingClasses.filter((classSlot) => !classSlot.completed)
                  .length ? (
                  upcomingClasses
                    .filter((classSlot) => !classSlot.completed)
                    .map((classSlot) => (
                      <Paper
                        key={classSlot.id}
                        className={classes.upcomingClasses}
                        elevation={6}
                      >
                        <Typography gutterBottom>
                          <strong>Reference Number:</strong> {classSlot.id}
                        </Typography>
                        <Typography gutterBottom>
                          <strong>Course Name:</strong> {classSlot.course}
                        </Typography>
                        <Typography gutterBottom>
                          <strong>Start Date:</strong>{" "}
                          {moment(classSlot.startDate).format(
                            "Do MMMM 'YY, dddd"
                          )}
                        </Typography>
                        <Typography gutterBottom>
                          <strong>End Date:</strong>{" "}
                          {moment(classSlot.endDate).format(
                            "Do MMMM 'YY, dddd"
                          )}
                        </Typography>
                        <Typography gutterBottom>
                          <strong>Time Slots:</strong>
                        </Typography>
                        {Object.entries(classSlot.slots).map(
                          ([day, time], key) => (
                            <div style={{ marginLeft: 10 }} key={key}>
                              <Typography>
                                <strong>{day}: </strong>
                                {time} EST
                              </Typography>
                              <br />
                            </div>
                          )
                        )}
                        <Typography gutterBottom>
                          <strong>Google Meet Link:</strong>{" "}
                          <a href={`${classSlot.meetingLink}`}>
                            {classSlot.meetingLink || "Not yet created"}
                          </a>
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          {classSlot.completed && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                              }}
                            >
                              <Typography>Class Completed</Typography>
                              <Verified color="success" fontSize="large" />
                            </div>
                          )}
                        </div>
                      </Paper>
                    ))
                ) : (
                  <Typography variant="h5" textAlign="center">
                    No upcoming classes ☹️
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Container>
        </Paper>
      )}
    </Container>
  );
};

const Demo = ({ demo }) => {
  return (
    <Paper className={classes.upcomingDemo} elevation={6}>
      <Typography gutterBottom>
        <strong>Reference Number:</strong> {demo.id}
      </Typography>
      <Typography gutterBottom>
        <strong>Course:</strong> {demo.course}
      </Typography>
      <Typography gutterBottom>
        <strong>Duration:</strong> 30 mins
      </Typography>
      <Typography gutterBottom>
        <strong>Scheduled Date:</strong>{" "}
        {moment(demo.slot.toDate()).format("Do MMMM 'YY")}
      </Typography>
      <Typography gutterBottom>
        <strong>Time Slot:</strong>
        {moment(demo.slot.toDate()).format(" h:mm A")} -{" "}
        {moment(demo.slot.toDate()).add(30, "minute").format(" h:mm A")} EST
      </Typography>
      <Typography gutterBottom>
        <strong>Google Meet Link:</strong>{" "}
        <a href={`${demo.meetingLink}`}>
          {demo.meetingLink || "Not yet created"}
        </a>
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          component={Link}
          to={`/book-class?demo-ref=${demo.id}`}
        >
          Book Class
        </Button>
        {demo.classReference && (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <Typography>Demo Completed</Typography>
            <Verified color="success" fontSize="large" />
          </div>
        )}
      </div>
    </Paper>
  );
};
export default Dashboard;
