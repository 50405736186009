// CRUD FUNCTIONS FOR Classes in Firestore
import { collection, getDocs, getDoc, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { generateRefNum } from "./demo"

export const createClass = async (data) => {
	const referenceNumber = generateRefNum()
	const documentRef = doc(db, "classes", `${referenceNumber}`)
	await setDoc(documentRef, data)
	await saveClassInDemo(referenceNumber, data.demoReference)
	return referenceNumber
}

const saveClassInDemo = async (classRef, demoRef) => {
	const documentRef = doc(db, 'demos', demoRef)
	await updateDoc(documentRef, { classReference: classRef })
}

export const fetchClassesByUser = async phone => {
	const result = []
	const classes = await fetchClasses()
	classes.forEach(classes =>{
		if (classes.phone === phone) result.push(classes)
	})
	return result.filter(classes => classes.amountReceived)
}

export const fetchClasses = async () => {
	const result = []
	const snap = await getDocs(collection(db, "classes"))
	snap.forEach(classSlot => result.push({ id: classSlot.id, ...classSlot.data() }))
	return result
}

const saveClassInUser = async (phone, referenceNumber) => {
	const documentRef = doc(db, "users", phone)
	const snap = await getDoc(documentRef)
	if (!snap.exists()) {
		return console.error("user Not found")
	}
	const user = snap.data()

	const payload = {
		classReferences: [...user.classReferences, referenceNumber],
	}
	await updateDoc(documentRef, payload)
}

export const updateClass = async (id, data) => {
	const documentRef = doc(db, "classes", id)
	if (data.amountReceived) {
		saveClassInUser(data.phone, data.id)
	} else {
		deleteClassFromUser(data.phone, data.id)
	}
	delete data.id
	await updateDoc(documentRef, data)
}

export const deleteClass = async referenceId => {
	const documentRef = doc(db, "classes", referenceId)
	const snap = await getDoc(documentRef)

	const userPhone = snap.data().phone
	await deleteDoc(doc(db, "classes", referenceId))
	await deleteClassFromUser(userPhone, referenceId)
}

const deleteClassFromUser = async (phone, referenceId) => {
	const documentRef = doc(db, "users", phone)
	const snap = await getDoc(documentRef)
	const user = snap.data()
	const payload = {
		classReferences: user.classReferences.filter(ref => ref.toString() !== referenceId),
	}
	await updateDoc(documentRef, payload)
}

// export const saveClassIn