import { ArrowLeft } from "@mui/icons-material";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { Container, classes } from "styles/BookDemoStyles";

const PaymentGateway = () => {
  //   const details = {
  //     "Account Holder Name": process.env.REACT_APP_ACCOUNT_HOLDER_NAME,
  //     "Account Number": process.env.REACT_APP_ACCOUNT_NUMBER,
  //     "Bank Name": process.env.REACT_APP_BANK_NAME,
  //     Branch: process.env.REACT_APP_BRANCH,
  //     "IFSC CODE": process.env.REACT_APP_IFSC_CODE,
  //     "UPI ID": process.env.REACT_APP_UPI_ID,
  //   };
  return (
    <Container className={classes.root}>
      <Paper elevation={6} component="main" className={classes.paper}>
        <Button
          startIcon={<ArrowLeft />}
          variant="contained"
          component="a"
          href="/"
          style={{ backgroundColor: "#ffb343" }}
        >
          BACK
        </Button>
        <Typography
          variant="h4"
          textAlign="center"
          gutterBottom
          style={{ textDecoration: "underline", color: "#0042B6" }}
        >
          Thank you for choosing us!
        </Typography>
        <Grid container>
          <Grid item xs={12} lg={6}>
            {/* {Object.entries(details).map(([label, data]) => (
							<Typography key={label} gutterBottom variant="h6">
								<strong>{`${label}: `}</strong> {data}
							</Typography>
						))} */}
          </Grid>
        </Grid>
        <Typography>
          We have reserved your requested time slot for the next three days. A
          member of our team will contact you through WhatsApp with payment
          details. Once payment is processed, your classes will be scheduled for
          the days and times you requested. <br />
          If you have any questions, please do not hesitate to{" "}
          <a href={`/contact-us`}> contact us</a>.{" "}
        </Typography>
      </Paper>
    </Container>
  );
};

export default PaymentGateway;
