// CRUD FUNCTIONS FOR Courses in Firestore
import {
  updateDoc,
  doc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

export const createCourse = async (data) => {
  const documentRef = doc(collection(db, "courses"));
  await setDoc(documentRef, data);
};
export const fetchCourse = async (name) => {
  const courses = await fetchCourses();
  return { ...courses.filter((course) => course.name === name)[0] };
};

export const fetchCourses = async () => {
  const courses = [];
  const snap = await getDocs(collection(db, "courses"));
  snap.forEach((doc) => courses.push({ id: doc.id, ...doc.data() }));
  return courses;
};
export const updateCourse = async ({ id, name, duration, cost }) => {
  const documentRef = doc(db, "courses", id);
  const payload = { name, duration, cost };
  await updateDoc(documentRef, payload);
};
export const deleteCourse = async (id) =>
  await deleteDoc(doc(db, "courses", id));
