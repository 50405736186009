// CRUD FUNCTIONS FOR Users in Firestore
import { doc, setDoc, serverTimestamp, getDoc, collection, getDocs, deleteDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"

export const createUser = async ({ firstName, lastName, phone, email }) => {
	const trimmedPhoneNo = phone.replace(/ /g, "")
	const documentRef = doc(db, "users", trimmedPhoneNo)
	const snap = await getDoc(documentRef)
	if (snap.exists()) {
		return
	}
	const payload = {
		name: `${firstName} ${lastName}`,
		demoReferences: [],
		classReferences: [],
		createdAt: serverTimestamp(),
		email: email || "",
	}
	await setDoc(documentRef, payload)
}

export const fetchUser = async phone => {
	const trimmedPhoneNo = phone.replace(/ /g, "")
	const documentRef = doc(db, "users", trimmedPhoneNo)
	const snap = await getDoc(documentRef)
	if (snap.exists()) {
		return { ...snap.data(), phone: snap.id }
	}
}

export const fetchUsers = async () => {
	const users = []
	const snap = await getDocs(collection(db, "users"))
	snap.forEach(doc => users.push({ phone: doc.id, ...doc.data() }))
	return users
}

export const deleteUser = async phone => {
	const trimmedPhoneNo = phone.replace(/ /g, "")
	await deleteDoc(doc(db, "users", trimmedPhoneNo))
}

export const isExistingUser = async phone => {
	const trimmedPhoneNo = phone.replace(/ /g, "")
	const documentRef = doc(db, "users", trimmedPhoneNo)
	const snap = await getDoc(documentRef)
	return snap.exists()
}

export const updateUser = async (phone, data) => {
	const documentRef = doc(db, "users", phone)
	await updateDoc(documentRef, data)
}

export const hasBooked = async phone => {
	const trimmedPhoneNo = phone.replace(/ /g, "")
	const documentRef = doc(db, "users", trimmedPhoneNo)
	const snap = await getDoc(documentRef)
	if (snap.exists()) {
		return snap.data().meetingLink
	}
}

