import { School, Dashboard, Group, Home, AccessTime, Book, Mail, Diversity3, EventAvailable, DateRange } from "@mui/icons-material"
import { useNavigate, useLocation } from "react-router-dom"

import { Typography } from "@mui/material"
import { classes, Container } from "styles/Admin/SidebarStyles"

const Modules = [
	{
		title: "Home",
		icon: <Home />,
		link: "/admin/dashboard",
	},
	{
		title: "Users",
		icon: <Group />,
		link: "/admin/dashboard/users",
	},
	{
		title: "Demos",
		icon: <AccessTime />,
		link: "/admin/dashboard/demos",
	},
	{
		title: "Schedules",
		icon: <EventAvailable />,
		link: "/admin/dashboard/schedules",
	},
	{
		title: "Slots",
		icon: <DateRange />,
		link: "/admin/dashboard/slots",
	},
	{
		title: "Classes",
		icon: <School />,
		link: "/admin/dashboard/classes",
	},
	{
		title: "Mentors",
		icon: <Diversity3 />,
		link: "/admin/dashboard/mentors",
	},

	{
		title: "Courses",
		icon: <Book />,
		link: "/admin/dashboard/courses",
	},
	{
		title: "Contact Messages",
		icon: <Mail />,
		link: "/admin/dashboard/messages",
	},
	{
		title: "Landing Page",
		icon: <Dashboard />,
		link: "/",
	},

]

const Sidebar = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const isInDashboard = location.pathname.startsWith("/admin/dashboard")
	return (
		<Container className={classes.root} style={{ display: isInDashboard ? "flex" : "none" }}>
			<Typography variant="h6" className={classes.logo} noWrap>
				ADMIN PANNEL
			</Typography>
			<ul className={classes.list}>
				{Modules.map((val, key) => (
					<li key={key} className={classes.row} id={window.location.pathname === val.link ? "active" : ""} onClick={() => navigate(val.link)}>
						<div className={classes.icon}>{val.icon}</div>
						<div className={classes.title}>{val.title}</div>
					</li>
				))}
			</ul>
		</Container>
	)
}

export default Sidebar
