import { Button } from "@mui/material";

export const SecondaryButton = ({ eventListener, text, ...others }) => {
  return (
    <Button
      style={{
        minWidth: 148,
        background: "#2a2850",
        height: 43,
        padding: "0 23px",
        boxShadow: "4px 4px 4px #8280a2",
        borderRadius: 8,
        color: "white",
        fontSize: 17,
      }}
      onClick={eventListener}
      {...others}
    >
      {text}
    </Button>
  );
};

export const SecondaryButton2 = ({ eventListener, text, ...others }) => {
  return (
    <Button
      style={{
        background: "#2a2850",
        height: 43,
        padding: "0 23px",
        boxShadow: "none",
        borderRadius: 5,
        marginLeft: 0,
        color: "white",
        width: "100%",
      }}
      onClick={eventListener}
      {...others}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;
