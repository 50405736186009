import {
	Avatar,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogActions,
	Divider,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Typography,
	Grid,
	DialogContent,
	FormControlLabel,
	Tooltip,
	Checkbox,
	ButtonGroup,
	OutlinedInput as MuiInput,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@mui/material"

import { fetchSchedule, getInitialSlots } from "context/dbActions/schedule"
import { Delete, Edit, Search, School, Remove, Add } from "@mui/icons-material"
import Input from "components/Input"
import { classes, Container } from "styles/Admin/UserStyles"
import { useState, useEffect, useMemo, useCallback } from "react"
import moment from "moment"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { fetchMentors } from "context/dbActions/mentor"
import { fetchClasses, updateClass, deleteClass, createClass } from "context/dbActions/class"
import { fetchCourse } from "context/dbActions/course"
import { fetchSlots } from "context/dbActions/slots"

const DeleteDialogBox = ({ setDeleting, deleting, classSlot, refresh }) => {
	const [loading, setLoading] = useState(false)
	const handleClose = () => setDeleting(false)

	const handleConfirm = async () => {
		setLoading(true)
		await deleteClass(classSlot.id)
		setLoading(false)
		refresh()
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={deleting} maxWidth="xs">
			<DialogTitle textAlign="center">
				Are you sure you want to delete {classSlot.course} class for {classSlot.name}?
			</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const EditDialogBox = ({ setEditing, editing, classSlot, refresh }) => {
	const initialState = {
		enrolledFor: 1,
		amountReceived: false,
		completed: false,
		endDate: moment().format("MM/DD/YYYY"),
		startDate: moment().format("MM/DD/YYYY"),
		meetingLink: "",
		slots: {},
		months: 0,
		classes: 0,
		totalClasses: 0,
		totalAmount: 0,
		email: "",
		mentor: "",
	}
	const [days, setDays] = useState([])
	const [slots, setSlots] = useState([])
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const [cost, setCost] = useState(0)
	const handleClose = () => setEditing(false)
	useEffect(() => {
		if (classSlot.id) {
			setFormData({
				...classSlot,
				startDate: classSlot.startDate || initialState.startDate,
				endDate: classSlot.endDate || initialState.endDate,
				enrolledFor: classSlot.enrolledFor || initialState.enrolledFor,
				completed: classSlot.completed || initialState.completed,
			})
			getCourse(classSlot.course)
			getSlots(classSlot.mentor)
		}
	}, [classSlot])
	const [slotDay, setSlotDay] = useState("")
	const [slotTime, setSlotTime] = useState("")

	const getCourse = async courseName => {
		const course = await fetchCourse(courseName)
		setCost(course.cost)
	}
	const getSlots = async mentor => {
		const initDays = await getInitialSlots()
		const data = await fetchSchedule(mentor)
		const days = Object.keys(initDays)
		const slots = Object.keys(data[days[0]])
		setSlots(slots)
		setDays(days)
	}
	const handleConfirm = async () => {
		setLoading(true)
		await updateClass(classSlot.id, formData)
		setLoading(false)
		refresh()
		handleClose()
	}

	const StaticText = ({ label, data }) => (
		<div style={{ display: "flex", gap: 10 }}>
			<Typography style={{ whiteSpace: "nowrap" }}>
				<strong>{label}</strong>
			</Typography>
			<Typography>{data}</Typography>
		</div>
	)
	const handleChange = e => {
		let value = e.target.value
		if (["amountReceived", "completed"].includes(e.target.name)) {
			value = value !== "true"
		}
		setFormData({
			...formData,
			[e.target.name]: value,
		})
	}
	useEffect(() => {
		setFormData({ ...formData, totalAmount: formData.classes * 4 * formData.months * cost, totalClasses: formData.classes * 4 })
	}, [formData.months, formData.classes])

	const handleDateChange = (name, value) => {
		setFormData({ ...formData, [name]: value.format("MM/DD/YYYY") })
	}
	const addSlot = () => {
		if (!slotDay && !slotTime) {
			return
		}
		const finalSlots = { ...formData.slots, [slotDay]: slotTime }
		if (Object.keys(finalSlots).length > formData.classes) {
			return
		}
		setFormData({ ...formData, slots: finalSlots })
	}
	const removeSlot = day => {
		const finalSlots = formData.slots
		delete finalSlots[day]
		setFormData({ ...formData, slots: finalSlots })
	}
	return (
		<Dialog onClose={handleClose} open={editing} maxWidth="xs">
			<DialogTitle textAlign="center">Editing details of Class: {classSlot.id}</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<StaticText label="Name: " data={`${classSlot.name}`} />
				<StaticText label="Demo Reference: " data={`${classSlot.demoReference}`} />
				<StaticText label="Phone: " data={`${classSlot.phone}`} />
				<StaticText label="Total Classes: " data={`${formData.totalClasses}`} />
				<StaticText label="Total Amount: " data={`$${formData.totalAmount}`} />
				<Typography className={classes.label}>Months</Typography>
				<Input value={formData.months} name="months" onChange={handleChange} placeholder="eg. 1" />
				<Typography className={classes.label}>Classes Per Week</Typography>
				<Input value={formData.classes} name="classes" onChange={handleChange} placeholder="eg. 1" />
				<Typography className={classes.label}>Enrolled For</Typography>
				<MuiInput
					value={formData.enrolledFor}
					name="enrolledFor"
					onChange={handleChange}
					placeholder="eg. 1st/2nd/3rd/.."
					endAdornment={<InputAdornment position="end">times</InputAdornment>}
					variant="outlined"
				/>
				<Typography className={classes.label}>Slots Booked</Typography>
				{Object.entries(formData.slots).map(([day, time]) => (
					<ButtonGroup key={day}>
						<Button variant="contained">{day}</Button>
						<Button variant="outlined">{time}</Button>
						<IconButton color="error" onClick={() => removeSlot(day)}>
							<Remove />
						</IconButton>
					</ButtonGroup>
				))}

				<div style={{ display: "flex", gap: 10 }}>
					<FormControl fullWidth>
						<InputLabel id="label">Day</InputLabel>
						<Select labelId="label" name="slotDay" value={slotDay} label="Day" onChange={e => setSlotDay(e.target.value)}>
							{days.length ? (
								days.map(day => (
									<MenuItem key={day} value={day}>
										{day.length ? day : <em>None</em>}
									</MenuItem>
								))
							) : (
								<MenuItem style={{ display: "flex", justifyContent: "center" }}>
									<CircularProgress />
								</MenuItem>
							)}
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<InputLabel id="label">Time</InputLabel>
						<Select labelId="label" name="slotTime" value={slotTime} label="Time" onChange={e => setSlotTime(e.target.value)}>
							{slots.length ? (
								slots.map(slot => (
									<MenuItem key={slot} value={slot}>
										{slot.length ? slot : <em>None</em>}
									</MenuItem>
								))
							) : (
								<MenuItem style={{ display: "flex", justifyContent: "center" }}>
									<CircularProgress />
								</MenuItem>
							)}
						</Select>
					</FormControl>
					<IconButton color="success" onClick={addSlot}>
						<Add />
					</IconButton>
				</div>
				<Typography className={classes.label}>Meeting Link</Typography>
				<Input value={formData.meetingLink} name="meetingLink" onChange={handleChange} placeholder="eg. https://meet.google.com/abc-def-zid" />
				<Typography className={classes.label}>Email</Typography>
				<Input value={formData.email} name="email" onChange={handleChange} placeholder="eg. abc@def.com" />
				<Typography className={classes.label}>Mentor</Typography>
				<Input value={formData.mentor} name="mentor" onChange={handleChange} placeholder="eg. YOGAMEN1" />
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<Typography className={classes.label}>Start Date</Typography>
					<DesktopDatePicker
						label="Start Date"
						inputFormat="MM/DD/YYYY"
						value={formData.startDate}
						onChange={value => handleDateChange("startDate", value)}
						renderInput={params => <Input {...params} placeholder="eg. 12/12/12" />}
					/>
					<Typography className={classes.label}>End Date</Typography>
					<DesktopDatePicker
						label="End Date"
						inputFormat="MM/DD/YYYY"
						value={formData.endDate}
						onChange={value => handleDateChange("endDate", value)}
						renderInput={params => <Input {...params} placeholder="eg. 12/12/12" />}
					/>
				</LocalizationProvider>
				<div>
					<FormControlLabel control={<Checkbox name="amountReceived" onChange={handleChange} checked={formData.amountReceived} value={formData.amountReceived} />} label="Amount Recieved" />
					<FormControlLabel control={<Checkbox name="completed" onChange={handleChange} checked={formData.completed} value={formData.completed} />} label="Class Completed" />
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>

				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const CreateDialogBox = ({ setCreating, creating, refresh }) => {
	const initialState = {
		name: "",
		phone: "",
		email: "",
		course: "",
		mentor: "",
		classes: "",
		months: "",
		totalClasses: "",
		totalAmount: "",
		demoReference: "",
		slots: {},
		completed: false,
		amountReceived: false,
		enrolledFor: "",
	}
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const handleClose = () => setCreating(false)
	const [days, setDays] = useState([])
	const [slots, setSlots] = useState([])
	const [slotDay, setSlotDay] = useState("")
	const [slotTime, setSlotTime] = useState("")
	const handleConfirm = async () => {
		setLoading(true)
		try {
			await createClass(formData)
		} catch (err) {
			console.log(err)
		}
		setFormData(initialState)
		setLoading(false)
		refresh()
		handleClose()
	}
	useEffect(() => {
		getSlots()
	}, [])

	const getSlots = useCallback(async () => {
		const initDays = await getInitialSlots()
		const days = Object.keys(initDays)
		const slots = Object.keys(initDays[days[0]])
		setDays(days)
		setSlots(slots)
	}, [days])
	const handleChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}
	const addSlot = () => {

		if (!slotDay && !slotTime) {
			return
		}
		const finalSlots = { ...formData.slots, [slotDay]: slotTime }
		setFormData({ ...formData, slots: finalSlots })
	}
	const removeSlot = day => {
		const finalSlots = formData.slots
		delete finalSlots[day]
		setFormData({ ...formData, slots: finalSlots })
	}

	return (
		<Dialog onClose={handleClose} open={creating} maxWidth="xs">
			<DialogTitle textAlign="center">Adding a new class</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<Typography className={classes.label}>Name</Typography>
				<Input value={formData.name} name="name" onChange={handleChange} placeholder="Firstname Lastname" />
				<Typography className={classes.label}>Phone Number</Typography>
				<Input value={formData.phone} name="phone" onChange={handleChange} placeholder="+11 234 1212 121" />
				<Typography className={classes.label}>Email</Typography>
				<Input value={formData.email} name="email" onChange={handleChange} placeholder="abc@def.com" />
				<Typography className={classes.label}>Course</Typography>
				<Input value={formData.course} name="course" onChange={handleChange} placeholder="Guitar" />
				<Typography className={classes.label}>Mentor</Typography>
				<Input value={formData.mentor} name="mentor" onChange={handleChange} placeholder="GUITARMEN1" />
				<Typography className={classes.label}>Classes</Typography>
				<Input value={formData.classes} name="classes" onChange={handleChange} placeholder="1, 2 or 4" />
				<Typography className={classes.label}>Months</Typography>
				<Input value={formData.months} name="months" onChange={handleChange} placeholder="1, 2 or 4" />
				<Typography className={classes.label}>Total Classes</Typography>
				<Input value={formData.totalClasses} name="totalClasses" onChange={handleChange} placeholder="4, 8, 16" />
				<Typography className={classes.label}>Total Amount</Typography>
				<Input value={formData.totalAmount} name="totalAmount" onChange={handleChange} placeholder="eg. 124" />
				<Typography className={classes.label}>Demo Reference</Typography>
				<Input value={formData.demoReference} name="demoReference" onChange={handleChange} placeholder="eg. 12345678" />

				<Typography className={classes.label}>Slots</Typography>
				{Object.entries(formData.slots).map(([day, time]) => (
					<ButtonGroup key={day}>
						<Button variant="contained">{day}</Button>
						<Button variant="outlined">{time}</Button>
						<IconButton color="error" onClick={() => removeSlot(day)}>
							<Remove />
						</IconButton>
					</ButtonGroup>
				))}

				<div style={{ display: "flex", gap: 10 }}>
					<FormControl fullWidth>
						<InputLabel id="label">Day</InputLabel>
						<Select labelId="label" name="slotDay" value={slotDay} label="Day" onChange={e => setSlotDay(e.target.value)}>
							{days.length ? (
								days.map(day => (
									<MenuItem key={day} value={day}>
										{day.length ? day : <em>None</em>}
									</MenuItem>
								))
							) : (
								<MenuItem style={{ display: "flex", justifyContent: "center" }}>
									<CircularProgress />
								</MenuItem>
							)}
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<InputLabel id="label">Time</InputLabel>
						<Select labelId="label" name="slotTime" value={slotTime} label="Time" onChange={e => setSlotTime(e.target.value)}>
							{slots.length ? (
								slots.map(slot => (
									<MenuItem key={slot} value={slot}>
										{slot.length ? slot : <em>None</em>}
									</MenuItem>
								))
							) : (
								<MenuItem style={{ display: "flex", justifyContent: "center" }}>
									<CircularProgress />
								</MenuItem>
							)}
						</Select>
					</FormControl>
					<IconButton color="success" onClick={addSlot}>
						<Add />
					</IconButton>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Classes = () => {
	const [loading, setLoading] = useState(false)
	const [classSlots, setClassSlots] = useState([])
	const [classSlot, setClassSlot] = useState({})
	const [deleting, setDeleting] = useState(false)
	const [editing, setEditing] = useState(false)
	const [creating, setCreating] = useState(false)
	const [mentors, setMentors] = useState([])
	const [query, setQuery] = useState("")
	const filterUnpaid = classSlot => !classSlot.amountReceived
	const filterOngoing = classSlot => classSlot.amountReceived && !classSlot.completed
	const filterCompleted = classSlot => classSlot.completed

	const filteredClassSlots = useMemo(
		() =>
			classSlots.filter(classSlot => {
				const value = query.toLowerCase()
				return Object.values(classSlot).some(item => item?.toString().toLowerCase().includes(value))
			}),
		[classSlots, query]
	)

	useEffect(() => {
		setLoading(true)
		getClasses()
	}, [])

	const getClasses = async () => {
		const classes = await fetchClasses()
		const mentors = await fetchMentors()
		setClassSlots(classes)
		setMentors(mentors)
		setLoading(false)
	}
	const handleEdit = classSlot => {
		setClassSlot(classSlot)
		setEditing(true)
	}
	const handleDelete = user => {
		setClassSlot(user)
		setDeleting(true)
	}

	const dialogBoxProps = { classSlot, refresh: getClasses, deleting, setDeleting, editing, setEditing, mentors, creating, setCreating }
	return (
		<Container className={classes.root}>
			<CreateDialogBox {...dialogBoxProps} />
			{classSlot && <DeleteDialogBox {...dialogBoxProps} />}
			{classSlot && <EditDialogBox {...dialogBoxProps} />}
			<div className={classes.header}>
				<div>
					<div style={{ display: "flex", gap: 10, alignItems: "center" }}>
						<Avatar className={classes.addAvatar}>
							<IconButton onClick={() => setCreating(true)}>
								<Add className={classes.addIcon} />
							</IconButton>
						</Avatar>
						<Typography variant="h4">Classes</Typography>
					</div>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredClassSlots.filter(filterUnpaid).length}{" "}
						{`Class${filteredClassSlots.filter(filterUnpaid).length === 1 ? " is" : "es are"}`} unpaid
					</Typography>
				</div>
				<Input
					value={query}
					onChange={e => setQuery(e.target.value)}
					style={{ width: 350 }}
					placeholder="Search"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredClassSlots.filter(filterUnpaid).map(classSlot => (
							<div key={classSlot.id}>
								<ListItem
									className={classes.listItem}
									style={{ paddingRight: 100 }}
									secondaryAction={
										<div className={classes.listItemActions}>
											<Tooltip title={`Amount ${classSlot.amountReceived ? "" : "not"} Recieved`}>
												<Checkbox color="success" checked={classSlot.amountReceived} />
											</Tooltip>
											<Tooltip title="Edit">
												<IconButton edge="end" onClick={() => handleEdit(classSlot)}>
													<Edit color="success" />
												</IconButton>
											</Tooltip>
											<Tooltip title="Edit">
												<IconButton edge="end" onClick={() => handleDelete(classSlot)}>
													<Delete color="error" />
												</IconButton>
											</Tooltip>
										</div>
									}
								>
									<Grid container>
										<Grid item xs={4} style={{ display: "flex" }}>
											<ListItemAvatar>
												<Avatar>
													<School />
												</Avatar>
											</ListItemAvatar>
											<div>
												<ListItemText primary={`${classSlot.id} | ${classSlot.course}`} secondary={`Phone: ${classSlot.phone}`} />
												<Typography style={{ fontSize: 15 }}>Demo Reference: {classSlot.demoReference || ""}</Typography>
											</div>
										</Grid>
										<Grid item lg={4}>
											<ListItemText primary={`For: ${classSlot.name}`} secondary={`Mentor: ${classSlot.mentor}`} />
											<Typography style={{ fontSize: 15 }}>Email: {classSlot.email || ""}</Typography>
										</Grid>
										<Grid item lg={4}>
											<ListItemText
												primary={`Classes per Week ${classSlot.classes} | Months: ${classSlot.months}`}
												secondary={`Total Amount: $${classSlot.totalAmount} for ${classSlot.totalClasses} classes`}
											/>
										</Grid>
									</Grid>
								</ListItem>
								<Divider className={classes.divider} />
							</div>
						))}
					</List>
				</div>
			)}
			<div className={classes.header}>
				<div>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredClassSlots.filter(filterOngoing).length}{" "}
						{`Class${filteredClassSlots.filter(filterOngoing).length === 1 ? " is" : "es are"}`} on-going
					</Typography>
				</div>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredClassSlots.filter(filterOngoing).map(classSlot => (
							<div key={classSlot.id}>
								<ListItem
									className={classes.listItem}
									style={{ paddingRight: 100 }}
									secondaryAction={
										<div className={classes.listItemActions}>
											<Tooltip title={`Amount ${classSlot.amountReceived ? "" : "not"} Recieved`}>
												<Checkbox color="success" checked={classSlot.amountReceived} />
											</Tooltip>
											<Tooltip title="Edit">
												<IconButton edge="end" onClick={() => handleEdit(classSlot)}>
													<Edit color="success" />
												</IconButton>
											</Tooltip>
											<Tooltip title="Edit">
												<IconButton edge="end" onClick={() => handleDelete(classSlot)}>
													<Delete color="error" />
												</IconButton>
											</Tooltip>
										</div>
									}
								>
									<Grid container>
										<Grid item xs={4} style={{ display: "flex" }}>
											<ListItemAvatar>
												<Avatar>
													<School />
												</Avatar>
											</ListItemAvatar>
											<div>
												<ListItemText primary={`${classSlot.id} | ${classSlot.course}`} secondary={`Phone: ${classSlot.phone}`} />
												<Typography style={{ fontSize: 15 }}>Demo Reference: {classSlot.demoReference || ""}</Typography>
											</div>
										</Grid>
										<Grid item lg={4}>
											<ListItemText primary={`For: ${classSlot.name}`} secondary={`Mentor: ${classSlot.mentor}`} />
											<Typography style={{ fontSize: 15 }}>Email: {classSlot.email || ""}</Typography>
										</Grid>
										<Grid item lg={4}>
											<ListItemText
												primary={`Classes per Week ${classSlot.classes} | Months: ${classSlot.months}`}
												secondary={`Total Amount: $${classSlot.totalAmount} for ${classSlot.totalClasses} classes`}
											/>
										</Grid>
									</Grid>
								</ListItem>
								<Divider className={classes.divider} />
							</div>
						))}
					</List>
				</div>
			)}
			<div className={classes.header}>
				<div>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredClassSlots.filter(filterCompleted).length}{" "}
						{`Class${filteredClassSlots.filter(filterCompleted).length === 1 ? " is" : "es are"}`} completed
					</Typography>
				</div>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredClassSlots.filter(filterCompleted).map(classSlot => (
							<div key={classSlot.id}>
								<ListItem
									disablePadding
									className={classes.listItem}
									style={{ paddingRight: 100 }}
									secondaryAction={
										<div className={classes.listItemActions}>
											<Tooltip title={`Amount ${classSlot.amountReceived ? "" : "not"} Recieved`}>
												<Checkbox color="success" checked={classSlot.amountReceived} />
											</Tooltip>
											<Tooltip title="Edit">
												<IconButton edge="end" onClick={() => handleEdit(classSlot)}>
													<Edit color="success" />
												</IconButton>
											</Tooltip>
											<Tooltip title="Edit">
												<IconButton edge="end" onClick={() => handleDelete(classSlot)}>
													<Delete color="error" />
												</IconButton>
											</Tooltip>
										</div>
									}
								>
									<Grid container>
										<Grid item xs={4} style={{ display: "flex" }}>
											<ListItemAvatar>
												<Avatar>
													<School />
												</Avatar>
											</ListItemAvatar>
											<div>
												<ListItemText primary={`${classSlot.id} | ${classSlot.course}`} secondary={`Phone: ${classSlot.phone}`} />
												<Typography style={{ fontSize: 15 }}>Demo Reference: {classSlot.demoReference || ""}</Typography>
											</div>
										</Grid>
										<Grid item lg={4}>
											<ListItemText primary={`For: ${classSlot.name}`} secondary={`Mentor: ${classSlot.mentor}`} />
											<Typography style={{ fontSize: 15 }}>Email: {classSlot.email || ""}</Typography>
										</Grid>
										<Grid item lg={4}>
											<ListItemText
												primary={`Classes per Week ${classSlot.classes} | Months: ${classSlot.months}`}
												secondary={`Total Amount: $${classSlot.totalAmount} for ${classSlot.totalClasses} classes`}
											/>
										</Grid>
									</Grid>
								</ListItem>
								<Divider className={classes.divider} />
							</div>
						))}
					</List>
				</div>
			)}
		</Container>
	)
}

export default Classes
