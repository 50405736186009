import {
  Button,
  Card,
  ButtonBase,
  ButtonGroup,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import { classes } from "styles/CoursesStyles";
import PrimaryButton from "components/PrimaryButton";

export const CourseCard = ({
  value,
  title,
  showFees,
  key,
  bookDemo,
  ...other
}) => {
  return (
    <Grid item sm={12} md={6} lg={4} key={key}>
      <ButtonBase>
        <Card
          className={classes.card}
          elevation={5}
          style={{ height: "500px" }}
        >
          <CardMedia
            component="img"
            image={value.image}
            alt={title}
            className={classes.media}
          />
          <CardContent className={classes.cardContent}>
            <div className={classes.title} style={{ fontSize: "10px" }}>
              <Typography
                style={{
                  fontSize: "22px",
                  textDecoration: "underline",
                  fontWeight: "500",
                  fontColor: "#000000",
                }}
                className={classes.titleText}
                children={`${value.title}`}
              />
            </div>
            <Typography
              style={{
                fontSize: "15px",
                margin: "15px",
              }}
              className={classes.tagLine}
              variant="body1"
              textAlign="center"
            >
              {value.tagline}
            </Typography>
            <Divider
              className={classes.divider}
              style={{ marginBottom: "10px" }}
            />
            <CardActions
              className={classes.actions}
              style={{ marginTop: "5px" }}
            >
              <ButtonGroup variant="text">
                <Button component="div" className={classes.buttons}>
                  Per Month:
                  <br />
                  {value.noOfClasses}
                  <br />
                  Duration: {value.duration}
                  {showFees ? (
                    <>
                      <br />
                      Fee: {value.fees}
                    </>
                  ) : null}
                </Button>
              </ButtonGroup>
              <br />
            </CardActions>
            {bookDemo != null ? (
              <PrimaryButton
                component="div"
                eventListener={bookDemo}
                text="Book a FREE Demo"
                style={{
                  marginTop: "20px",
                  backgroundColor: " #FFB343",
                  fontSize: 13,
                  boxShadow: "none",
                  height: 35,
                }}
              />
            ) : null}
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};
