import { Grid, Typography } from "@mui/material";
import { classes, Container } from "styles/EnrollStyles";
import PrimaryButton from "../PrimaryButton";

const Enroll = ({ bookDemo }) => {
  const steps = [
    {
      title: "Share Your Child's Profile",
      detail:
        "Contact us on WhatsApp to share insights about your child's learning needs. We customize our approach to suit them best.",
    },
    {
      title: "Connect with Our Counselor",
      detail:
        "Engage in a conversation with our counselor to articulate your expectations. They'll pair your child with the perfect tutor for a trial session.",
    },
    {
      title: "Schedule the FREE demo!",
      detail:
        "Observe your child's active learning experience with our tutors and have all your queries addressed.",
    },
    {
      title: "Choose your slots and Pay",
      detail:
        "Select your preferred slots and make your payment. Begin with a monthly package and renew classes each month. No obligation to commit to 3 or 6 months upfront.",
    },
  ];
  return (
    <Container className={classes.root}>
      <Typography className={classes.heading}>
        A Comprehensive Walkthrough for Course Registration, Step by Step{" "}
      </Typography>
      <Grid className={classes.gridContainer} container spacing={3}>
        {steps.map((step, key) => (
          <Grid item xs={12} sm={6} md={8} lg={3} key={key}>
            <Typography variant="h4">{key + 1}.</Typography>
            <Typography className={classes.gridTitle} variant="h5">
              {step.title}
            </Typography>
            <Typography className={classes.gridDetail} variant="h6">
              {step.detail}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <PrimaryButton
        style={{
          backgroundColor: " #FFB343",
          fontSize: 15,
          boxShadow: "4px 2.5px 2.5px #aeaeae",
        }}
        eventListener={bookDemo}
        text="Book a FREE Trial Class"
      />
    </Container>
  );
};

export default Enroll;
