import Banner from "./Banner"
import Enroll from "./Enroll"
// import Caraousel from "./Caraousel"
import Courses from "./Courses"
import ChooseUs from "./ChooseUs"
import ParentsVoice from "./ParentsVoice"
import Mentors from "./Mentors"
import { useNavigate } from "react-router-dom"

const Home = ({ bookDemo, openLogin }) => {
	const navigate = useNavigate()

	return (
		<>
			<Banner bookDemo={() => bookDemo(navigate)} openLogin={openLogin} />
			<Enroll bookDemo={() => bookDemo(navigate)} />
			<Courses bookDemo={() => bookDemo(navigate)} />
			{/* <Caraousel /> */}
			<ChooseUs />
			<ParentsVoice />
			<Mentors />
		</>
	)
}

export default Home
