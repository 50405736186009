import { styled } from "@mui/material/styles";
import MuiContainer from "@mui/material/Container";

const PREFIX = "Banner";

export const classes = {
  root: `${PREFIX}-root`,
  textUpper: `${PREFIX}-textUpper`,
  text: `${PREFIX}-text`,
  textContainer: `${PREFIX}-textContainer`,
  textLower: `${PREFIX}-textLower`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  primaryButton: `${PREFIX}-primaryButton`,
  secondaryButton: `${PREFIX}-secondaryButton`,
  imageContainer: `${PREFIX}-imageContainer`,
  image: `${PREFIX}-image`,
  lineBreak: `${PREFIX}-lineBreak`,
  arrow1: `${PREFIX}-arrow1`,
  login: `${PREFIX}-login`,
  contactus: `${PREFIX}-contactus`,
};

export const Container = styled(MuiContainer)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "100px 150px",
    maxWidth: "100%",
    position: "relative",
    alignItems: "center",
  },
  [`& .${classes.textUpper}`]: {
    fontSize: 22,
    letterSpacing: 2,
    color: "#FFB343",
    fontWeight: 550,
  },
  [`& .${classes.text}`]: {
    fontWeight: 650,
    fontSize: 52,
    textShadow: "#929292 2px 2px 2px",
    color: "#0042B6",
  },
  [`& .${classes.textLower}`]: {
    color: "#B9A9A9",
    // color: "#F9EAFF",
    fontWeight: 400,
    fontSize: 20,
    margin: "20px 0",
  },
  [`& .${classes.buttonGroup}`]: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 20,
  },
  [`& .${classes.primaryButton}`]: {
    background: "#fcfe96",
    height: 43,
    // boxShadow: "2px 2px 2px #BE9797",
    borderRadius: 40,
    color: "white",
    textTransform: "none",
    padding: "13px 40px",
  },
  [`& .${classes.secondaryButton}`]: {
    background: "#2A2850",
    height: 43,
    // fontSize: 20,
    boxShadow: "4px 4px 4px #BE9797",
    borderRadius: 40,
    color: "white",
    padding: "13px 40px",
    textTransform: "none",
  },
  [`& .${classes.contactus}`]: {
    display: "flex",
    minWidth: 148,
    background: "#2a2850",
    height: 43,
    padding: "0 23px",
    boxShadow: "4px 4px 4px #8280a2",
    borderRadius: 8,
    color: "white",
    fontSize: 17,
  },
  [`& .${classes.login}`]: {
    display: "none",
    minWidth: 148,
    background: "#2a2850",
    height: 43,
    padding: "0 23px",
    boxShadow: "4px 4px 4px #8280a2",
    borderRadius: 8,
    color: "white",
    fontSize: 17,
  },
  [`& .${classes.imageContainer}`]: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  [`& .${classes.image}`]: {
    top: 188,
    height: 170,
    width: 170,
    backgroundColor: "#a481d1",
    borderRadius: 5,
    color: "white",
    position: "absolute",
    rotate: "45deg",
    justifyContent: "center",
  },
  [`& .${classes.arrow1}`]: {
    width: 125,
    position: "absolute",
    top: 15,
    left: "calc(100vw - 563px)",
    zIndex: -9,
  },
  [theme.breakpoints.down(1600)]: {
    [`& .${classes.arrow1}`]: {
      width: 125,
      position: "absolute",
      top: -9,
      left: "calc(100vw - 373px)",
      zIndex: -9,
    },
  },
  [theme.breakpoints.down("md")]: {
    [`& .${classes.contactus}`]: {
      display: "none",
    },
    [`& .${classes.login}`]: {
      display: "flex",
    },
    [`&.${classes.root}`]: {
      flexDirection: "column-reverse",
      height: "auto",
    },
    [`& .${classes.textContainer}`]: {
      maxWidth: 442,
      alignSelf: "center",
      marginBottom: 14,
    },
    [`& .${classes.imageContainer}`]: {
      height: 200,
      marginBottom: 15,
      display: "none",
    },
    [`& .${classes.image}`]: {
      top: 0,
    },
    [`& .${classes.arrow1}`]: {
      left: "calc(100vw - 376px)",
    },
  },
  [theme.breakpoints.down("sm")]: {
    [`&.${classes.root}`]: {
      textAlign: "center",
      height: "auto",
      padding: "0 28px",
    },
    [`& .${classes.arrow1}`]: {
      display: "none",
    },
    [`& .${classes.imageContainer}`]: {
      display: "none",
    },
    [`& .${classes.textUpper}`]: {
      fontSize: 25,
    },
    [`& .${classes.text}`]: {
      fontSize: 39,
      margin: "24px 0",
    },
    [`& .${classes.textLower}`]: {
      fontSize: 15,
    },
    [`& .${classes.buttonGroup}`]: {
      flexDirection: "column",
      gap: 15,
    },
    [`& .${classes.lineBreak}`]: {
      display: "none",
    },
  },
}));
