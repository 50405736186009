import { styled } from "@mui/material/styles"
import MuiContainer from "@mui/material/Container"

const PREFIX = "Users"

export const classes = {
	root: `${PREFIX}-root`,
	header: `${PREFIX}-header`,
	subHeading: `${PREFIX}-subHeading`,
	listItemActions: `${PREFIX}-listItemActions`,
	listItem: `${PREFIX}-listItem`,
	addAvatar: `${PREFIX}-addAvatar`,
	addIcon: `${PREFIX}-addIcon`,
	divider: `${PREFIX}-divider`,
	label: `${PREFIX}-label`,
	loadingPaper: `${PREFIX}-loadingPaper`,
}

export const Container = styled(MuiContainer)(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: 20,
		maxWidth: "85%",
		marginLeft: 270,
	},
	[`& .${classes.header}`]: {
		display: "flex",
		justifyContent: "space-between",
	},
	[`& .${classes.subHeading}`]: {
		margin: "10px 0",
		display: "flex",
		alignItems: "center",
		gap: 15,
	},
	[`& .${classes.label}`]: {
		width: "100%",
		margin: "4px 0px 0px 8px",
	},
	[`& .${classes.listItemActions}`]: {
		display: "flex",
		gap: 10,
	},
	[`& .${classes.listItem}`]: {
		display: "flex",
		gap: 20,
	},
	[`& .${classes.addAvatar}`]: {
		backgroundColor: "green",
		margin: "5px 0",
	},
	[`& .${classes.addIcon}`]: {
		color: "white",
	},
	[`& .${classes.divider}`]: {
		backgroundColor: "black",
	},
	[`& .${classes.loadingPaper}`]: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 20,
	},
}))