import {
	Avatar,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogActions,
	Divider,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Typography,
	Grid,
} from "@mui/material"

import { Delete, Search, Call, Mail, Message } from "@mui/icons-material"
import Input from "components/Input"
import { classes, Container } from "styles/Admin/UserStyles"
import { useState, useEffect, useMemo } from "react"
import { deleteMessage, fetchMessages } from "context/dbActions/message"

const DeleteDialogBox = ({ setDeleting, deleting, message, refresh }) => {
	const [loading, setLoading] = useState(false)
	const handleClose = () => setDeleting(false)

	const handleConfirm = async () => {
		setLoading(true)
		await deleteMessage(message.id)
		setLoading(false)
		refresh()
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={deleting}>
			<DialogTitle textAlign="center">Are you sure you want to delete message from {message.name}?</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Messages = () => {
	const [loading, setLoading] = useState(false)
	const [messages, setMessages] = useState([])
	const [message, setMessage] = useState({})
	const [deleting, setDeleting] = useState(false)
	const [query, setQuery] = useState("")

	const filteredMessages = useMemo(
		() =>
			messages.filter(message => {
				const value = query.toLowerCase()
				return Object.values(message).some(item => item?.toString().toLowerCase().includes(value))
			}),
		[messages, query]
	)

	useEffect(() => {
		setLoading(true)
		getMessages()
	}, [])

	const handleDelete = message => {
		setMessage(message)
		setDeleting(true)
	}
	const getMessages = async () => {
		const data = await fetchMessages()
		setMessages(data)
		setLoading(false)
	}
	const dialogBoxProps = { message, refresh: getMessages, deleting, setDeleting }
	return (
		<Container className={classes.root}>
			{message && <DeleteDialogBox {...dialogBoxProps} />}
			<div className={classes.header}>
				<div>
					<Typography variant="h4">Messages</Typography>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredMessages.length} {`Message${filteredMessages.length === 1 ? " is" : "s are"}`} posted
					</Typography>
				</div>
				<Input
					value={query}
					onChange={e => setQuery(e.target.value)}
					style={{ width: 350 }}
					placeholder="Search"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredMessages.map(message => (
							<div key={message.id}>
								<ListItem
									className={classes.listItem}
									style={{ paddingRight: 100 }}
									secondaryAction={
										<div className={classes.listItemActions}>
											<IconButton edge="end" component="a" href={`tel: ${message.phone}`}>
												<Call color="primary" />
											</IconButton>
											<IconButton edge="end" component="a" href={`mailto: ${message.email}`}>
												<Mail color="success" />
											</IconButton>
											<IconButton edge="end" onClick={() => handleDelete(message)}>
												<Delete color="error" />
											</IconButton>
										</div>
									}
								>
									<ListItemAvatar>
										<Avatar>
											<Message />
										</Avatar>
									</ListItemAvatar>
									<Grid>
										<Typography fontWeight="bold">{message.name}</Typography>
										<ListItemText primary={`${message.message}`} secondary={`Email: ${message.email}  | ${message.phone}`} />
									</Grid>
								</ListItem>
								<Divider className={classes.divider} />
							</div>
						))}
					</List>
				</div>
			)}
		</Container>
	)
}

export default Messages
