import { Edit, RestartAlt, Search, Schedule as ScheduleIcon } from "@mui/icons-material"
import SlotTable from "./SlotTable"
import {
	Avatar,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Typography,
} from "@mui/material"
import Input from "components/Input"
import { useEffect, useMemo, useState } from "react"
import { classes, Container } from "styles/Admin/UserStyles"
import { fetchSchedules, getInitialSlots, resetSchedule, updateSchedule } from "context/dbActions/schedule"
import { fetchMentors } from "context/dbActions/mentor"

const ResetDialogBox = ({ setReseting, reseting, schedule, refresh }) => {
	const [loading, setLoading] = useState(false)
	const handleClose = () => setReseting(false)

	const handleConfirm = async () => {
		setLoading(true)
		await resetSchedule(schedule.id)
		setLoading(false)
		refresh()
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={reseting} maxWidth="xs">
			<DialogTitle textAlign="center">Are you sure you want to reset all slots for {schedule.id}?</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const EditDialogBox = ({ setEditing, editing, schedule, refresh, mentor, initialSlots }) => {
	const [formData, setFormData] = useState(initialSlots)
	const [loading, setLoading] = useState(false)
	const handleClose = () => setEditing(false)

	useEffect(() => {
		if (schedule.id) {
			setFormData(schedule)
		}
	}, [schedule])
	const handleConfirm = async () => {
		setLoading(true)
		await updateSchedule(schedule.id, formData)
		setLoading(false)
		refresh()
		handleClose()
	}
	return (
		<Dialog onClose={handleClose} open={editing} maxWidth="xl">
			<DialogTitle textAlign="center">
				Editing slots of {schedule.id}: {mentor.name}
			</DialogTitle>
			<DialogContentText textAlign="center">
				All the timeZones are in EST
			</DialogContentText>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<SlotTable schedule={formData} setFormData={setFormData} initialSlots={initialSlots} />
			</DialogContent>
			<DialogActions style={{ padding: "10px 20px", justifyContent: "space-between" }}>
				<div>
					<FormControlLabel control={<Checkbox checked />} label="Scheduled" />
					<FormControlLabel control={<Checkbox checked={false} />} label="Available" />
				</div>
				<div>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
						CONFIRM
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	)
}

const Schedules = () => {
	const [loading, setLoading] = useState(false)
	const [schedule, setSchedule] = useState({})
	const [mentor, setMentor] = useState({})
	const [reseting, setReseting] = useState(false)
	const [editing, setEditing] = useState(false)
	const [schedules, setSchedules] = useState([])
	const [mentors, setMentors] = useState([])
	const [creating, setCreating] = useState(false)
	const [query, setQuery] = useState("")
	const [initialSlots, setInitialSlots] = useState({})
	const filteredSchedules = useMemo(
		() =>
			schedules.filter(schedule => {
				const value = query.toLowerCase()
				return Object.values(schedule).some(item => item.toString().toLowerCase().includes(value))
			}),
		[schedules, query]
	)

	useEffect(() => {
		setLoading(true)
		getData()
	}, [])

	const getData = async () => {
		const schedules = await fetchSchedules()
		const mentors = await fetchMentors()
		const initialSlots = await getInitialSlots()
		setInitialSlots(initialSlots)
		setSchedules(schedules)
		setMentors(mentors)
		setLoading(false)
	}
	const handleEdit = (schedule, mentor) => {
		setSchedule(schedule)
		setMentor(mentor)
		setEditing(true)
	}

	const handleReset = mentor => {
		setSchedule(mentor)
		setReseting(true)
	}

	const dialogBoxProps = { initialSlots, schedule, refresh: getData, reseting, setReseting, editing, setEditing, schedules, creating, setCreating, mentor, setMentor }

	return (
		<Container className={classes.root}>
			{schedule && <ResetDialogBox {...dialogBoxProps} />}
			{schedule && <EditDialogBox {...dialogBoxProps} />}
			<div className={classes.header}>
				<div>
					<Typography variant="h4">Schedules</Typography>
					<Typography className={classes.subHeading} variant="h6" component="div">
						Schedule for {loading ? <CircularProgress size="1.5rem" /> : filteredSchedules.length} {`Mentor${filteredSchedules.length === 1 ? " is" : "s are"}`} available
					</Typography>
				</div>
				<Input
					value={query}
					onChange={e => setQuery(e.target.value)}
					style={{ width: 350 }}
					placeholder="Search"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredSchedules.map(schedule => (
							<Schedule schedule={schedule} key={schedule.id} handleEdit={handleEdit} handleReset={handleReset} mentor={mentors.filter(mentor => mentor.id === schedule.id)[0]} />
						))}
					</List>
				</div>
			)}
		</Container>
	)
}

const Schedule = ({ schedule, handleEdit, handleReset, mentor }) => {
	return (
		<div>
			<List>
				<ListItem
					disablePadding
					className={classes.listItem}
					style={{ paddingRight: 100 }}
					secondaryAction={
						<div className={classes.listItemActions}>
							<IconButton edge="end" onClick={() => handleEdit(schedule, mentor)}>
								<Edit color="success" />
							</IconButton>
							<IconButton edge="end" onClick={() => handleReset(schedule)}>
								<RestartAlt color="primary" />
							</IconButton>
						</div>
					}
				>
					<Grid container>
						<Grid item lg={6} style={{ display: "flex", alignItems: "center", gap: 5 }}>
							<ListItemAvatar>
								<Avatar>
									<ScheduleIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={`Mentor Id: ${schedule.id}`} secondary={`Teaches ${schedule.course}`} />
						</Grid>
						<Grid item lg={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
							<ListItemText primary={`Name: ${mentor.name}`} />
						</Grid>
					</Grid>
				</ListItem>
			</List>
			<Divider className={classes.divider} />
		</div>
	)
}

export default Schedules
