import { Grid, Typography } from "@mui/material";
import { Container, classes } from "styles/MentorsStyles";
import mentorImage from "assets/Mentor.png";
import { LibraryAddCheckOutlined } from "@mui/icons-material";

const Mentors = () => {
  return (
    <Container className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.text} gutterBottom>
          Our Mentors
        </Typography>
        <Typography variant="h3" className={classes.paragraph} gutterBottom>
          We on board trained professionals who are experts in their respective
          fields.{" "}
        </Typography>
      </div>
      <Grid container spacing={10} style={{ justifyContent: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            padding: "40px 12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img className={classes.image} src={mentorImage} alt="mentors" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <div>
            <Typography className={classes.paragraph}>
              Our trainers undergo a rigorous selection process to ensure that
              the quality of teaching that children receive is of the highest
              standard.
            </Typography>
            <div className={classes.points}>
              <div className={classes.checkpoint}>
                <LibraryAddCheckOutlined color="success" fontSize="medium" />
                <Typography className={classes.point}>
                  Customized teaching methods{" "}
                </Typography>
              </div>
              <div className={classes.checkpoint}>
                <LibraryAddCheckOutlined color="success" fontSize="medium" />
                <Typography className={classes.point}>
                  Ability to identify and address learning challenges
                </Typography>
              </div>
              <div className={classes.checkpoint}>
                <LibraryAddCheckOutlined color="success" fontSize="medium" />
                <Typography className={classes.point}>
                  Use of a variety of teaching techniques
                </Typography>
              </div>
              <div className={classes.checkpoint}>
                <LibraryAddCheckOutlined color="success" fontSize="medium" />
                <Typography className={classes.point}>
                  Timely feedback and support
                </Typography>
              </div>
              <div className={classes.checkpoint}>
                <LibraryAddCheckOutlined color="success" fontSize="medium" />
                <Typography className={classes.point}>
                  Use of technology and other resources to enhance learning
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Mentors;
