import { Add, Book, Delete, Edit, Search } from "@mui/icons-material"
import { Avatar, CircularProgress, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from "@mui/material"
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from "@mui/material"
import Input from "components/Input"
import { useEffect, useMemo, useState } from "react"
import { classes, Container } from "styles/Admin/UserStyles"
import { createCourse, deleteCourse, fetchCourses, updateCourse } from "context/dbActions/course"

const CreateDialogBox = ({ setCreating, creating, refresh }) => {
	const initialState = { name: "", duration: 1, cost: 0 }
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const handleClose = () => setCreating(false)

	const handleConfirm = async () => {
		setLoading(true)
		await createCourse(formData)
		setFormData(initialState)
		setLoading(false)
		refresh()
		handleClose()
	}
	const handleChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	return (
		<Dialog onClose={handleClose} open={creating}>
			<DialogTitle textAlign="center">Adding a new course</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<Typography className={classes.label}>Course Name</Typography>
				<Input value={formData.name} name="name" onChange={handleChange} placeholder="Course Name" />
				<Typography className={classes.label}>Duration</Typography>
				<Input value={formData.duration} name="duration" type="number" placeholder="Duration (in Hrs.)" onChange={handleChange} />
				<Typography className={classes.label}>Cost per session</Typography>
				<Input value={formData.cost} name="cost" type="number" placeholder="Cost (eg. $12)" onChange={handleChange} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}
const DeleteDialogBox = ({ setDeleting, deleting, course, refresh }) => {
	const [loading, setLoading] = useState(false)
	const handleClose = () => setDeleting(false)

	const handleConfirm = async () => {
		setLoading(true)
		await deleteCourse(course.id)
		setLoading(false)
		refresh()
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={deleting}>
			<DialogTitle>Are you sure you want to delete {course.name}?</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}
const EditDialogBox = ({ setEditing, editing, course, refresh }) => {
	const [formData, setFormData] = useState(course)
	const [loading, setLoading] = useState(false)
	const handleClose = () => setEditing(false)
	useEffect(() => {
		if (course.id) {
			setFormData(course)
		}
	}, [course.id])
	const handleConfirm = async () => {
		setLoading(true)
		await updateCourse(formData)
		setLoading(false)
		refresh()
		handleClose()
	}
	const handleChange = e => {
		const value = e.target.name === "name" ? e.target.value : parseInt(e.target.value)
		setFormData({ ...formData, [e.target.name]: value })
	}
	return (
		<Dialog onClose={handleClose} open={editing}>
			<DialogTitle textAlign="center">Editing details of {course.name}</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<Typography className={classes.label}>Course Name</Typography>
				<Input value={formData.name} name="name" onChange={handleChange} placeholder="Course Name" />
				<Typography className={classes.label}>Duration</Typography>
				<Input value={formData.duration} name="duration" type="number" placeholder="Duration (in Hrs.)" onChange={handleChange} />
				<Typography className={classes.label}>Cost per session</Typography>
				<Input value={formData.cost} name="cost" type="number" placeholder="Cost (eg. $12)" onChange={handleChange} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>

				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Courses = () => {
	const [loading, setLoading] = useState(false)
	const [courses, setCourses] = useState([])
	const [course, setCourse] = useState(null)
	const [editing, setEditing] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [creating, setCreating] = useState(false)
	const [query, setQuery] = useState("")
	
	const filteredCourses = useMemo(
		() =>
			courses.filter(course => {
				const value = query.toLowerCase()
				return Object.values(course).some(item => item.toString().toLowerCase().includes(value))
			}),
		[courses, query]
	)

	useEffect(() => {
		setLoading(true)
		getCourses()
	}, [])

	const handleEdit = course => {
		setCourse(course)
		setEditing(true)
	}

	const handleDelete = course => {
		setCourse(course)
		setDeleting(true)
	}

	const getCourses = async () => {
		const data = await fetchCourses()
		setCourses(data)
		setLoading(false)
	}

	const dialogBoxProps = { course, refresh: getCourses, deleting, setDeleting, editing, setEditing, creating, setCreating }
	return (
		<Container className={classes.root}>
			{course && <DeleteDialogBox {...dialogBoxProps} />}
			{course && <EditDialogBox {...dialogBoxProps} />}
			<CreateDialogBox {...dialogBoxProps} />
			<div className={classes.header}>
				<div>
					<Typography variant="h4">Courses</Typography>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredCourses.length} {`Course${filteredCourses.length === 1 ? " is" : "s are"}`} running
					</Typography>
				</div>
				<Input
					value={query}
					onChange={e => setQuery(e.target.value)}
					style={{ width: 350 }}
					placeholder="Search"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredCourses.map(course => (
							<div key={course.id}>
								<ListItem
									className={classes.listItem}
									style={{ paddingRight: 100 }}
									secondaryAction={
										<div className={classes.listItemActions}>
											<IconButton edge="end" onClick={() => handleEdit(course)}>
												<Edit color="success" />
											</IconButton>
											<IconButton edge="end" onClick={() => handleDelete(course)}>
												<Delete color="error" />
											</IconButton>
										</div>
									}
								>
									<Grid container>
										<Grid item xs={6} style={{ display: "flex" }}>
											<ListItemAvatar>
												<Avatar>
													<Book />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary={`${course.name}`} secondary={`Duration: ${course.duration}hr`} />
										</Grid>
										<Grid item lg={6}>
											<ListItemText primary={`Cost: $${course.cost}/session`} />
										</Grid>
									</Grid>
								</ListItem>
								<Divider className={classes.divider} />
							</div>
						))}
						<ListItem>
							<ListItemAvatar>
								<Avatar className={classes.addAvatar}>
									<IconButton onClick={() => setCreating(true)}>
										<Add className={classes.addIcon} />
									</IconButton>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</List>
				</div>
			)}
		</Container>
	)
}

export default Courses
