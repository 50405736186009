import { Add, DateRangeOutlined, Delete, Search } from "@mui/icons-material"
import { Avatar, CircularProgress, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from "@mui/material"
import { Dialog, DialogTitle, DialogContent, Button, DialogActions} from "@mui/material"
import Input from "components/Input"
import { useEffect, useMemo, useState } from "react"
import { classes, Container } from "styles/Admin/UserStyles"
import { createSlot, deleteSlot, fetchSlots } from "context/dbActions/slots"
import moment from "moment"

const CreateDialogBox = ({ setCreating, creating, refresh }) => {
	const initialState = { time: "", available: true }
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)

	const handleClose = () => setCreating(false)

	const handleConfirm = async () => {
		setLoading(true)
		await createSlot(formData.time)
		setLoading(false)
		refresh()
		handleClose()
	}
	const handleChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	return (
		<Dialog onClose={handleClose} open={creating}>
			<DialogTitle textAlign="center">Adding a new Slot</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<Typography className={classes.label}>Slot Time</Typography>
				<Input value={formData.name} name="time" onChange={handleChange} placeholder="eg. 9AM" />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}
const DeleteDialogBox = ({ setDeleting, deleting, slot, refresh }) => {
	const [loading, setLoading] = useState(false)
	const handleClose = () => setDeleting(false)

	const handleConfirm = async () => {
		setLoading(true)
		await deleteSlot(slot)
		setLoading(false)
		refresh()
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={deleting}>
			<DialogTitle>Are you sure you want to delete slot {slot}?</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Slots = () => {
	const [loading, setLoading] = useState(false)
	const [slots, setSlots] = useState([])
	const [slot, setSlot] = useState(null)
	const [editing, setEditing] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [creating, setCreating] = useState(false)
	const [query, setQuery] = useState("")

	const filteredSlots = useMemo(
		() =>
			slots
				.filter(slot => {
					const value = query.toLowerCase()
					return slot.toLowerCase().includes(value)
				})
				.sort((time1, time2) => new moment(time1, "hh:mmA") - new moment(time2, "hh:mmA")),
		[slots, query]
	)

	useEffect(() => {
		setLoading(true)
		getSlots()
	}, [])

	const handleDelete = slot => {
		setSlot(slot)
		setDeleting(true)
	}

	const getSlots = async () => {
		const data = await fetchSlots()
		setSlots(Object.keys(data))
		setLoading(false)
	}

	const dialogBoxProps = { slot, refresh: getSlots, deleting, setDeleting, editing, setEditing, creating, setCreating }
	return (
		<Container className={classes.root}>
			{slot && <DeleteDialogBox {...dialogBoxProps} />}
			<CreateDialogBox {...dialogBoxProps} />
			<div className={classes.header}>
				<div>
					<Typography variant="h4">Slots</Typography>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredSlots.length} {`Slot${filteredSlots.length === 1 ? " is" : "s are"}`} available
					</Typography>
				</div>
				<Input
					value={query}
					onChange={e => setQuery(e.target.value)}
					style={{ width: 350 }}
					placeholder="Search"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredSlots.map(slot => (
							<div key={slot}>
								<ListItem
									className={classes.listItem}
									style={{ paddingRight: 100 }}
									secondaryAction={
										<div className={classes.listItemActions}>
											<IconButton edge="end" onClick={() => handleDelete(slot)}>
												<Delete color="error" />
											</IconButton>
										</div>
									}
								>
									<Grid container>
										<Grid item xs={6} style={{ display: "flex" }}>
											<ListItemAvatar>
												<Avatar>
													<DateRangeOutlined />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary={slot} />
										</Grid>
										<Grid item lg={6} />
									</Grid>
								</ListItem>
								<Divider className={classes.divider} />
							</div>
						))}
						<ListItem>
							<ListItemAvatar>
								<Avatar className={classes.addAvatar}>
									<IconButton onClick={() => setCreating(true)}>
										<Add className={classes.addIcon} />
									</IconButton>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</List>
				</div>
			)}
		</Container>
	)
}

export default Slots
