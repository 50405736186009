import { styled } from "@mui/material/styles"
import MuiContainer from "@mui/material/Container"

const PREFIX = "ContactUs"

export const classes = {
	root: `${PREFIX}-root`,
	header: `${PREFIX}-header`,
	heading: `${PREFIX}-heading`,
	label: `${PREFIX}-label`,
	subHeading: `${PREFIX}-subHeading`,
	image: `${PREFIX}-image`,
	form: `${PREFIX}-form`,
	errorLabel: `${PREFIX}-errorLabel`,
}

export const Container = styled(MuiContainer)(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: 100,
		position: "relative",
		minHeight: 950,
		maxWidth: "80%",
		marginBottom: 100,
	},
	[`& .${classes.header}`]: {
		maxWidth: "100%",
	},
	[`& .${classes.heading}`]: {
		color: "#ec4345",
		fontWeight: 600,
		fontSize: 40,
		letterSpacing: 2,
	},
	[`& .${classes.subHeading}`]: {
		maxWidth: 350,
	},
	[`& .${classes.image}`]: {
		position: "absolute",
		bottom: 0,
		right: 0,
		width: "70%",
		height: "75%",
		objectFit: "cover",
		borderRadius: "50px 0 0 0",
	},
	[`& .${classes.form}`]: {
		marginLeft: 60,
		position: "absolute",
		padding: 40,
		maxWidth: "350px",
		borderRadius: "20px 0 20px 0",
		marginTop: 90,
		border: "1px solid #afaeae",
	},
	[`& .${classes.errorLabel}`]: {
		fontStyle: "italic",
	},
	[theme.breakpoints.down("sm")]: {
		[`&.${classes.root}`]: {
			padding: 0,
			maxWidth: "90%",
		},
		[`& .${classes.heading}`]: {
			// fontSize: 35,
		},
		[`& .${classes.form}`]: {
			margin: 10,
			bottom: 0,
			padding: 17,
		},
		[`& .${classes.label}`]: {
			display: "none",
		},
		[`& .${classes.image}`]: {
			width: "100%",
			borderRadius: "30px 0 30px 0",
		},
	},
}))
