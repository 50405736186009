import {
	Avatar,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogActions,
	Divider,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Typography,
	Grid,
	DialogContent,
	Switch,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	Tooltip
} from "@mui/material"

import { Delete, Edit, Search, Flare, Add } from "@mui/icons-material"
import Input from "components/Input"
import { classes, Container } from "styles/Admin/UserStyles"
import { useState, useEffect, useMemo } from "react"
import moment from "moment"
import { createDemo, deleteDemo, fetchDemos, updateDemo } from "context/dbActions/demo"
import { fetchMentors } from "context/dbActions/mentor"

const CreateDialogBox = ({ setCreating, creating, refresh }) => {
	const initialState = {
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		course: "",
		date: "",
		timeSlot: "",
		mentor: "",
		ageGroup: "",
	}
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const handleClose = () => setCreating(false)

	const handleConfirm = async () => {
		setLoading(true)
		try{
			await createDemo(formData)
		} catch (err) {
			console.log(err)
		}
		setFormData(initialState)
		setLoading(false)
		refresh()
		handleClose()
	}
	const handleChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	return (
		<Dialog onClose={handleClose} open={creating} maxWidth="xs">
			<DialogTitle textAlign="center">Adding a new demo</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<Typography className={classes.label}>Name</Typography>
				<Grid container spacing={1}>
					<Input value={formData.firstName} half name="firstName" onChange={handleChange} placeholder="First Name" />
					<Input value={formData.lastName} half name="lastName" onChange={handleChange} placeholder="Last Name" />
				</Grid>
				<Typography className={classes.label}>Phone Number</Typography>
				<Input value={formData.phone} name="phone" onChange={handleChange} placeholder="+11 234 1212 121" />
				<Typography className={classes.label}>Email</Typography>
				<Input value={formData.email} name="email" onChange={handleChange} placeholder="abc@def.com" />
				<Typography className={classes.label}>Course</Typography>
				<Input value={formData.course} name="course" onChange={handleChange} placeholder="Guitar" />
				<Typography className={classes.label}>Date</Typography>
				<Input value={formData.date} name="date" onChange={handleChange} placeholder="MM/DD/YYYY" />
				<Typography className={classes.label}>Time Slot</Typography>
				<Input value={formData.timeSlot} name="timeSlot" onChange={handleChange} placeholder="13:20" />
				<Typography className={classes.label}>Mentor</Typography>
				<Input value={formData.mentor} name="mentor" onChange={handleChange} placeholder="GUITARMEN1" />
				<Typography className={classes.label}>Age Group</Typography>
				<Input value={formData.ageGroup} name="ageGroup" onChange={handleChange} placeholder="Between 5 - 10" />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const DeleteDialogBox = ({ setDeleting, deleting, demo, refresh }) => {
	const [loading, setLoading] = useState(false)
	const handleClose = () => setDeleting(false)

	const handleConfirm = async () => {
		setLoading(true)
		await deleteDemo(demo.id)
		setLoading(false)
		refresh()
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={deleting} maxWidth="xs">
			<DialogTitle textAlign="center">
				Are you sure you want to delete {demo.course} Demo for {demo.name}?
			</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const EditDialogBox = ({ setEditing, editing, demo, refresh, mentors }) => {
	const initialState = { completed: false, mentor: "", classReference: "", meetingLink: "", email: "" }
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const handleClose = () => {
		setEditing(false)
	}

	useEffect(() => {
		if (demo.id) {
			setFormData(demo)
		}
	}, [demo])
	const handleConfirm = async () => {
		setLoading(true)
		await updateDemo(demo.id, formData)
		setLoading(false)
		setFormData(initialState)
		refresh()
		handleClose()
	}
	const courseMentors = mentors.filter(mentor => mentor.teaches === demo.course)
	const mentorIds = [""]
	courseMentors.map(mentor => mentorIds.push(mentor.id))

	const StaticText = ({ label, data }) => (
		<div style={{ display: "flex", gap: 10 }}>
			<Typography style={{ whiteSpace: "nowrap" }}>
				<strong>{label}</strong>
			</Typography>
			<Typography>{data}</Typography>
		</div>
	)
	const handleChange = e => {
		const check = e.target.checked
		const value = e.target.value
		const isComplete = e.target.name === "completed"
		setFormData({ ...formData, [e.target.name]: isComplete ? check : value })
	}

	return (
		<Dialog onClose={handleClose} open={editing} maxWidth="xs">
			<DialogTitle textAlign="center">Editing details of Demo: {demo.id}</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<StaticText label="Name: " data={demo.name} />
				<StaticText label="Phone: " data={demo.phone} />
				<StaticText label="Age Group: " data={demo.ageGroup} />
				<StaticText label="Scheduled On: " data={moment(demo.slot?.toDate()).format("D/MM/YY dddd, hh:mm:ss")} />
				<Typography className={classes.label}>Mentor</Typography>
				<FormControl fullWidth className={classes.label}>
					<InputLabel id="select-label">Mentor</InputLabel>
					<Select labelId="select-label" name="mentor" value={formData.mentor} label="Course" onChange={handleChange}>
						{mentorIds.map((mentor, i) => (
							<MenuItem key={i} value={mentor}>
								{mentor.length ? mentor : <em>None</em>}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Typography className={classes.label}>Meeting Link</Typography>
				<Input value={formData.meetingLink} name="meetingLink" onChange={handleChange} placeholder="eg. https://meet.google.com/abc-def-ghi" />
				<Typography className={classes.label}>Email Id</Typography>
				<Input value={formData.email} name="email" onChange={handleChange} placeholder="eg. abc@def.com" />
				<Typography className={classes.label}>Class Reference</Typography>
				<Input value={formData.classReference} name="classReference" onChange={handleChange} placeholder="eg. 12321221" />
				<FormControlLabel control={<Switch name="completed" checked={formData.completed} onChange={handleChange} />} label="Completed" />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>

				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Demos = () => {
	const [loading, setLoading] = useState(false)
	const [demos, setDemos] = useState([])
	const [demo, setDemo] = useState({})
	const [deleting, setDeleting] = useState(false)
	const [editing, setEditing] = useState(false)
	const [creating, setCreating] = useState(false)
	const [mentors, setMentors] = useState([])
	const [query, setQuery] = useState("")

	const filteredDemos = useMemo(
		() =>
			demos.filter(demo => {
				const value = query.toLowerCase()
				return Object.values(demo).some(item => item?.toString().toLowerCase().includes(value))
			}),
		[demos, query]
	)

	useEffect(() => {
		setLoading(true)
		getDemos()
	}, [])

	const getDemos = async () => {
		const demos = await fetchDemos()
		const mentors = await fetchMentors()
		setDemos(demos)
		setMentors(mentors)
		setLoading(false)
	}
	const handleEdit = demo => {
		setDemo(demo)
		setEditing(true)
	}
	const handleDelete = user => {
		setDemo(user)
		setDeleting(true)
	}

	const dialogBoxProps = { demo, refresh: getDemos, deleting, setDeleting, editing, setEditing, mentors, creating, setCreating }
	return (
		<Container className={classes.root}>
			<CreateDialogBox {...dialogBoxProps} />
			{demo && <DeleteDialogBox {...dialogBoxProps} />}
			{demo && <EditDialogBox {...dialogBoxProps} />}
			<div className={classes.header}>
				<div>
					<div style={{ display: "flex", gap: 10, alignItems: "center" }}>
						<Avatar className={classes.addAvatar}>
							<IconButton onClick={() => setCreating(true)}>
								<Add className={classes.addIcon} />
							</IconButton>
						</Avatar>
						<Typography variant="h4">Demos</Typography>
					</div>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredDemos.length} {`Demo${filteredDemos.length === 1 ? " is" : "s are"}`} awaiting
					</Typography>
				</div>
				<Input
					value={query}
					onChange={e => setQuery(e.target.value)}
					style={{ width: 350 }}
					placeholder="Search"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredDemos.map(demo => (
							<div key={demo.id}>
								<ListItem
									className={classes.listItem}
									style={{ paddingRight: 100 }}
									secondaryAction={
										<div className={classes.listItemActions}>
											<Tooltip title={`Demo ${demo.completed ? "completed" : "awaiting"}`}>
												<Checkbox color="success" checked={demo.completed} />
											</Tooltip>
											<Tooltip title="Edit Demo">
												<IconButton edge="end" onClick={() => handleEdit(demo)}>
													<Edit color="success" />
												</IconButton>
											</Tooltip>
											<Tooltip title="Delete Demo">
												<IconButton edge="end" onClick={() => handleDelete(demo)}>
													<Delete color="error" />
												</IconButton>
											</Tooltip>
										</div>
									}
								>
									<Grid container>
										<Grid item xs={4} style={{ display: "flex" }}>
											<ListItemAvatar>
												<Avatar>
													<Flare />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary={`${demo.id} | ${demo.course}`} secondary={`Created on ${moment(demo.createdOn?.toDate()).format("Do MMMM YYYY \\at hh:mm:ss")}`} />
										</Grid>
										<Grid item lg={4}>
											<ListItemText primary={`For: ${demo.name}`} secondary={`Mentor: ${demo.mentor}`} />
											<Typography style={{ fontSize: 15 }}>Email: {demo.email}</Typography>
										</Grid>
										<Grid item lg={4}>
											<ListItemText primary={`Phone: ${demo.phone}`} secondary={`Scheuled on: ${moment(demo.slot?.toDate()).format("Do MMMM YYYY, dddd \\at hh:mm:ss")}`} />
										</Grid>
									</Grid>
								</ListItem>
								<Divider className={classes.divider} />
							</div>
						))}
					</List>
				</div>
			)}
		</Container>
	)
}

export default Demos
