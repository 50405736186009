import { useState } from "react"
import { DialogTitle, Grid, Button, CircularProgress } from "@mui/material"
import { Container, classes } from "styles/AuthStyles"
import { useNavigate } from "react-router-dom"
import Input from "../Input"
import { useAuth } from "context/AuthContext"

const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Auth = ({ snackBar }) => {
	const initialState = { email: "", password: "" }
	const initialError = { email: false, password: false }
	const [formData, setFormData] = useState(initialState)
	const [error, setError] = useState(initialError)
	const [loading, setLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const handleShowPassword = () => setShowPassword(prevShowPassword => !prevShowPassword)
	const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
	const { logIn, logOut } = useAuth()
	const navigate = useNavigate()

	const validate = () => {
		const errors = {
			email: !formData.email || !formData.email.match(regex),
			password: formData.password.length < 6,
		}
		setError(errors)
		return !(errors.email || errors.password)
	}

	const handleSubmit = async event => {
		event.preventDefault()
		if (!validate()) {
			return
		}
		setError(initialError)
		try {
			setLoading(true)
			logOut()
			await logIn(formData.email, formData.password)
			snackBar("success", 'Succesfully logged in')
			navigate("dashboard")
		} catch (error) {
			snackBar("error", error.message)
		}
		setLoading(false)
	}

	return (
		<Container component="main" maxWidth="xs" className={classes.root}>
			<DialogTitle textAlign="center">ADMIN PANNEL</DialogTitle>
			<form className={classes.form} onSubmit={handleSubmit}>
				<Grid container spacing={1}>
					<Input error={error.email} name="email" label="Email" type="email" onChange={handleChange} value={formData.email} autoFocus handleShowPassword={handleShowPassword} />
					<Input name="password" label="Password" onChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} error={error.password} />
				</Grid>
				<Button fullWidth startIcon={loading && <CircularProgress size="2rem" />} disabled={loading} variant="contained" color="primary" className={classes.submit} type="submit">
					LOG IN
				</Button>
				<Button fullWidth variant="contained" color="error" className={classes.submit} onClick={() => navigate("/")}>
					CANCEL
				</Button>
			</form>
		</Container>
	)
}

export default Auth
