// CRUD FUNCTIONS FOR Schedules in Firestore
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { fetchSlots } from "./slots"

export const fetchSchedules = async () => {
	const result = []
	const snap = await getDocs(collection(db, "schedules"))
	snap.forEach(schedule => result.push({ id: schedule.id, ...schedule.data() }))
	return result
}

export const createSchedule = async (mentorId, course) => {
	const documentRef = doc(db, "schedules", mentorId)
	const snap = await getDoc(documentRef)
	if (snap.exists()) {
		return console.error("Schedule already Exists for Mentor")
	}
	const initialSlots = getInitialSlots()
	const payload = {
		...initialSlots,
		course,
	}
	await setDoc(documentRef, payload)
}
export const toggleSchedule = async (mentorId, day, time) => {
	const documentRef = doc(db, "schedules", mentorId)
	const snap = await getDoc(documentRef)
	const schedule = snap.data()
	const payload = {
		[day]: {
			...schedule[day],
			[time]: !schedule[day][time],
		},
	}
	await updateDoc(documentRef, payload)
}

export const updateSchedule = async (id, data) => {
	const documentRef = doc(db, "schedules", id)
	delete data.id
	await updateDoc(documentRef, data)
}

export const resetSchedule = async mentorId => {
	const documentRef = doc(db, "schedules", mentorId)
	const initialSlots = await getInitialSlots()
	await updateDoc(documentRef, initialSlots)
}

export const getInitialSlots = async () => {
	const slots = await fetchSlots()
	const initialSlots = {
		Monday: slots,
		Tuesday: slots,
		Wednesday: slots,
		Thursday: slots,
		Friday: slots,
		Saturday: slots,
		Sunday: slots,
	}
	return initialSlots
}

export const fetchSchedule = async id => {
	const documentRef = doc(db, "schedules", id)
	const snap = await getDoc(documentRef)
	return { ...snap.data(), id: snap.id }
}
export const deleteSchedule = async id => await deleteDoc(doc(db, "schedules", id))
