import {
	Avatar,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogActions,
	Divider,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	
	ListItemText,
	Paper,
	Typography,
	Grid,
	DialogContent,
} from "@mui/material"

import { Add, Delete, Person, Search } from "@mui/icons-material"
import Input from "components/Input"
import { classes, Container } from "styles/Admin/UserStyles"
import { useState, useEffect, useMemo } from "react"
import moment from "moment"
import { createUser, deleteUser, fetchUsers } from "context/dbActions/user"

const CreateDialogBox = ({ setCreating, creating, refresh }) => {
	const initialState = { firstName: "", lastName: "", phone: "", email: "" }
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const handleClose = () => setCreating(false)

	const handleConfirm = async () => {
		setLoading(true)
		try {
			await createUser(formData)
		} catch (err) {
			console.log(err)
		}
		setFormData(initialState)
		setLoading(false)
		refresh()
		handleClose()
	}
	const handleChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	return (
		<Dialog onClose={handleClose} open={creating} maxWidth="xs">
			<DialogTitle textAlign="center">Adding a new user</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<Typography className={classes.label}>Name</Typography>
				<Grid container spacing={1}>
					<Input value={formData.firstName} half name="firstName" onChange={handleChange} placeholder="First Name" />
					<Input value={formData.lastName} half name="lastName" onChange={handleChange} placeholder="Last Name" />
				</Grid>
				<Typography className={classes.label}>Phone Number</Typography>
				<Input value={formData.phone} name="phone" onChange={handleChange} placeholder="+11 234 1212 121" />
				<Typography className={classes.label}>Email</Typography>
				<Input value={formData.email} name="email" onChange={handleChange} placeholder="abc@def.com" />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const DeleteDialogBox = ({ setDeleting, deleting, user, refresh }) => {
	const [loading, setLoading] = useState(false)
	const handleClose = () => setDeleting(false)

	const handleConfirm = async () => {
		setLoading(true)
		await deleteUser(user.phone)
		setLoading(false)
		refresh()
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={deleting}>
			<DialogTitle textAlign="center">
				Are you sure you want to delete {user.firstName} {user.lastName}?
			</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Users = () => {
	const [loading, setLoading] = useState(false)
	const [users, setUsers] = useState([])
	const [user, setUser] = useState({})
	const [deleting, setDeleting] = useState(false)
	const [creating, setCreating] = useState(false)
	const [query, setQuery] = useState("")

	const filteredUsers = useMemo(
		() =>
			users.filter(user => {
				const value = query.toLowerCase()
				return Object.values(user).some(item => item.toString().toLowerCase().includes(value))
			}),
		[users, query]
	)

	useEffect(() => {
		setLoading(true)
		getUsers()
	}, [])

	const handleDelete = user => {
		setUser(user)
		setDeleting(true)
	}

	const getUsers = async () => {
		const data = await fetchUsers()
		setUsers(data)
		setLoading(false)
	}
	const dialogBoxProps = { user, refresh: getUsers, deleting, setDeleting, creating, setCreating}
	return (
		<Container className={classes.root}>
			{user && <DeleteDialogBox {...dialogBoxProps} />}
			<CreateDialogBox {...dialogBoxProps} />
			<div className={classes.header}>
				<div>
					<div style={{ display: "flex", gap: 10, alignItems: "center" }}>
						<Avatar className={classes.addAvatar}>
							<IconButton onClick={() => setCreating(true)}>
								<Add className={classes.addIcon} />
							</IconButton>
						</Avatar>
						<Typography variant="h4">Users</Typography>
					</div>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredUsers.length} {`User${filteredUsers.length === 1 ? " is" : "s are"}`} enrolled
					</Typography>
				</div>
				<Input
					value={query}
					onChange={e => setQuery(e.target.value)}
					style={{ width: 350 }}
					placeholder="Search"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredUsers.map(user => (
							<div key={user.phone}>
								<ListItem
									className={classes.listItem}
									style={{ paddingRight: 100 }}
									secondaryAction={
										<div className={classes.listItemActions}>
											<IconButton edge="end" onClick={() => handleDelete(user)}>
												<Delete color="error" />
											</IconButton>
										</div>
									}
								>
									<Grid container>
										<Grid item xs={6} style={{ display: "flex" }}>
											<ListItemAvatar>
												<Avatar>
													<Person />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary={`${user.name}`} secondary={`Joined on ${moment(user.createdAt?.toDate()).format("Do MMMM YYYY \\at hh:mm:ss")}`} />
										</Grid>
										<Grid item lg={6}>
											<ListItemText
												primary={`Contact: ${user.phone}`}
												secondary={`Demos Booked: ${user.demoReferences.length} | Classes Enrolled: ${user.classReferences.length}`}
											/>
										</Grid>
									</Grid>
								</ListItem>
								<Divider className={classes.divider} />
							</div>
						))}
					</List>
				</div>
			)}
		</Container>
	)
}

export default Users
