import { styled } from "@mui/material/styles";
import MuiContainer from "@mui/material/Container";

const PREFIX = "ChooseUs";

export const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  text: `${PREFIX}-text`,
  subHeader: `${PREFIX}-subHeader`,
  point: `${PREFIX}-point`,
  pointText: `${PREFIX}-pointText`,
  question: `${PREFIX}-question`,
  icon: `${PREFIX}-icon`,
};

export const Container = styled(MuiContainer)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: "#f7fafd",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 30,
    gap: 30,
    maxWidth: 1000,
    padding: "60px 60px",
    margin: "100px auto",
  },
  [`& .${classes.header}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [`& .${classes.question}`]: {
    width: 55,
    height: 55,
    backgroundColor: "#e9ad00",
  },
  [`& .${classes.icon}`]: {
    width: 45,
    height: 45,
  },
  [`& .${classes.text}`]: {
    textShadow: "#929292 2px 2px 7px",
    fontSize: 42,
    textAlign: "center",
    display: "flex",
    gap: 10,
    marginBottom: 15,
  },
  [`& .${classes.subHeader}`]: {
    fontSize: 26,
    letterSpacing: 2,
    color: "#acacac",
    fontWeight: 600,
  },
  [`& .${classes.point}`]: {
    display: "flex",
    gap: 15,
    alignItems: "center",
    margin: 20,
  },
  [`& .${classes.pointText}`]: {
    fontSize: 22,
    color: "#acacac",
  },
  [theme.breakpoints.down("md")]: {
    [`&.${classes.root}`]: {
      // flexDirection: "column-reverse",
      height: "auto",
    },
  },
  [theme.breakpoints.down("sm")]: {
    [`& .${classes.question}`]: {
      width: 50,
      height: 50,
    },
    [`& .${classes.icon}`]: {
      width: 30,
      height: 30,
    },
    [`&.${classes.root}`]: {
      textAlign: "center",
      height: "auto",
      padding: "28px 5px",
    },
    [`& .${classes.text}`]: {
      fontSize: 39,
    },
    [`& .${classes.subHeader}`]: {
      fontSize: 23,
      fontWeight: 300,
    },
    [`& .${classes.point}`]: {
      alignItems: "flex-start",
    },
    [`& .${classes.pointText}`]: {
      textAlign: "left",
      fontSize: 22,
    },
  },
}));
