import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Checkbox } from "@mui/material"
import moment from "moment"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}))

const SlotTable = ({ schedule, setFormData, initialSlots }) => {
	const Days = Object.keys(initialSlots)
	const firstDay = Days[0]
	const timeSlots = schedule[firstDay]
	if (timeSlots === undefined) {
		return 
	}
	const Slots = Object.keys(timeSlots).sort((time1, time2) => new moment(time1, "hh:mmA") - new moment(time2, "hh:mmA"))
	const handleChange = (day, slot) => {
		const change = {
			...schedule,
			[day]: {
				...schedule[day],
				[slot]: !schedule[day][slot],
			},
		}

		setFormData(change)
	}
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 700 }} aria-label="customized table">
				<TableHead>
					<TableRow>
						<StyledTableCell>Day</StyledTableCell>
						{Slots.map(slot => (
							<StyledTableCell align="center" key={slot} children={slot} />
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{Days.map(day => (
						<StyledTableRow key={day}>
							<StyledTableCell component="th" scope="row" align="left">
								{day}
							</StyledTableCell>
							{Slots.map(slot => {
								const available = schedule[day][slot]
								return (
									<StyledTableCell align="center" key={slot}>
										<Checkbox value={available} onChange={() => handleChange(day, slot)} checked={!available} />
									</StyledTableCell>
								)
							})}
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default SlotTable
