import { Add, Delete, Diversity3, Edit, Search } from "@mui/icons-material"
import {
	Avatar,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	Typography,
} from "@mui/material"
import Input from "components/Input"
import { MuiTelInput } from "mui-tel-input"
import { createMentor, fetchMentors, deleteMentor, updateMentor } from "context/dbActions/mentor"
import { fetchCourses } from "context/dbActions/course"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { classes, Container } from "styles/Admin/UserStyles"

const CreateDialogBox = ({ setCreating, creating, refresh, courses }) => {
	const initialState = { name: "", id: "", teaches: "", email: "", phone: "" }
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const [phone, setPhone] = useState("")

	const handleClose = () => {
		setCreating(false)
		setFormData(initialState)
	}

	const handleConfirm = async () => {
		setLoading(true)
		await createMentor(formData)
		setLoading(false)
		refresh()
		handleClose()
	}
	const handleChange = e => {
		if (e.target) {
			setFormData({ ...formData, [e.target.name]: e.target.value })
			return
		}
		setPhone(e)
		setFormData({ ...formData, phone: e })
	}

	return (
		<Dialog onClose={handleClose} open={creating}>
			<DialogTitle textAlign="center">Adding a new mentor</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<Typography className={classes.label}>Mentor ID</Typography>
				<Input value={formData.id} name="id" onChange={handleChange} placeholder="(eg. GUITARMEN01)" />
				<Typography className={classes.label}>Name</Typography>
				<Input value={formData.name} name="name" onChange={handleChange} placeholder="Mentor Name" />
				<Typography className={classes.label}>Course</Typography>
				<FormControl fullWidth className={classes.label}>
					<InputLabel id="select-label">Course</InputLabel>
					<Select labelId="select-label" name="teaches" value={formData.teaches} label="Course" onChange={handleChange}>
						{courses.length ? (
							courses.map((course, i) => (
								<MenuItem key={i} value={course}>
									{course}
								</MenuItem>
							))
						) : (
							<MenuItem style={{ display: "flex", justifyContent: "center" }}>
								<CircularProgress textAlign="center" />
							</MenuItem>
						)}
					</Select>
				</FormControl>
				<Typography className={classes.label}>Email Address</Typography>
				<Input value={formData.email} name="email" onChange={handleChange} placeholder="Email address" />
				<Typography className={classes.label}>Phone Number</Typography>
				<MuiTelInput defaultCountry="US" name="phone" required placeholder="Phone Number" onChange={handleChange} value={phone} className={classes.label} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const DeleteDialogBox = ({ setDeleting, deleting, mentor, refresh }) => {
	const [loading, setLoading] = useState(false)
	const handleClose = () => setDeleting(false)

	const handleConfirm = async () => {
		setLoading(true)
		await deleteMentor(mentor.id)
		setLoading(false)
		refresh()
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={deleting} maxWidth="xs">
			<DialogTitle textAlign="center">Are you sure you want to delete {mentor.name}?</DialogTitle>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const EditDialogBox = ({ setEditing, editing, mentor, refresh }) => {
	const initialState = { name: "", teaches: "", id: "", email: "", phone: "" }
	const [formData, setFormData] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const [phone, setPhone] = useState("")
	const handleClose = () => {
		setEditing(false)
		setFormData(initialState)
		setPhone("")
	}

	useEffect(() => {
		if (mentor.id) {
			setFormData(mentor)
			setPhone(mentor.phone)
		}
	}, [editing])
	const handleConfirm = async () => {
		setLoading(true)
		await updateMentor(mentor.id, formData)
		setFormData(initialState)
		setLoading(false)
		refresh()
		handleClose()
	}

	const handleChange = e => {
		if (e.target) {
			setFormData({ ...formData, [e.target.name]: e.target.value })
			return
		}
		setPhone(e)
		setFormData({ ...formData, phone: e })
	}
	return (
		<Dialog onClose={handleClose} open={editing} maxWidth="xs">
			<DialogTitle textAlign="center">Editing details of {mentor.name}</DialogTitle>
			<DialogContent style={{ gap: 10, display: "flex", flexDirection: "column", minWidth: 350 }}>
				<Typography className={classes.label}>Name</Typography>
				<Input value={formData.name} name="name" onChange={handleChange} placeholder="Mentor Name" />
				<Typography className={classes.label}>Email Address</Typography>
				<Input value={formData.email} name="email" onChange={handleChange} placeholder="Email address" />
				<Typography className={classes.label}>Phone Number</Typography>
				<MuiTelInput defaultCountry="US" name="phone" required placeholder="Phone Number" onChange={handleChange} value={phone} className={classes.label} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm} startIcon={loading && <CircularProgress size="1rem" />} disabled={loading}>
					CONFIRM
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Mentors = () => {
	const [loading, setLoading] = useState(false)
	const [mentor, setMentor] = useState({})
	const [deleting, setDeleting] = useState(false)
	const [editing, setEditing] = useState(false)
	const [mentors, setMentors] = useState([])
	const [creating, setCreating] = useState(false)
	const [courses, setCourses] = useState([])
	const [query, setQuery] = useState("")

	const filteredMentors = useMemo(
		() =>
			mentors.filter(mentor => {
				const value = query.toLowerCase()
				return Object.values(mentor).some(item => item.toString().toLowerCase().includes(value))
			}),
		[mentors, query]
	)

	useEffect(() => {
		setLoading(true)
		getMentors()
	}, [])

	const getMentors = async () => {
		const mentors = await fetchMentors()
		const courses = await fetchCourses()
		setMentors(mentors)
		const courseNames = []
		courses.map(course => courseNames.push(course.name))
		setCourses(courseNames)
		setLoading(false)
	}
	const handleEdit = mentor => {
		setMentor(mentor)
		setEditing(true)
	}

	const handleDelete = mentor => {
		setMentor(mentor)
		setDeleting(true)
	}

	const dialogBoxProps = { mentor, refresh: getMentors, deleting, setDeleting, editing, setEditing, mentors, creating, setCreating, courses }

	return (
		<Container className={classes.root}>
			{mentor && <DeleteDialogBox {...dialogBoxProps} />}
			{mentor && <EditDialogBox {...dialogBoxProps} />}
			<CreateDialogBox {...dialogBoxProps} />
			<div className={classes.header}>
				<div>
					<Typography variant="h4">Mentors</Typography>
					<Typography className={classes.subHeading} variant="h6" component="div">
						{loading ? <CircularProgress size="1.5rem" /> : filteredMentors.length} {`Mentor${filteredMentors.length === 1 ? " is" : "s are"}`} available
					</Typography>
				</div>
				<Input
					value={query}
					onChange={e => setQuery(e.target.value)}
					style={{ width: 350 }}
					placeholder="Search"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Search />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{loading ? (
				<Paper className={classes.loadingPaper} elevation={6}>
					<CircularProgress size="4rem" />
				</Paper>
			) : (
				<div>
					<List>
						{filteredMentors.map(mentor => (
							<Mentor mentor={mentor} key={mentor.id} handleEdit={handleEdit} handleDelete={handleDelete} />
						))}
						<ListItem>
							<ListItemAvatar>
								<Avatar className={classes.addAvatar}>
									<IconButton onClick={() => setCreating(true)}>
										<Add className={classes.addIcon} />
									</IconButton>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</List>
				</div>
			)}
		</Container>
	)
}

const Mentor = ({ mentor, handleEdit, handleDelete }) => {
	return (
		<div>
			<ListItem
				className={classes.listItem}
				style={{ paddingRight: 100 }}
				secondaryAction={
					<div className={classes.listItemActions}>
						<IconButton edge="end" onClick={() => handleEdit(mentor)}>
							<Edit color="success" />
						</IconButton>
						<IconButton edge="end" onClick={() => handleDelete(mentor)}>
							<Delete color="error" />
						</IconButton>
					</div>
				}
			>
				<Grid container>
					<Grid item lg={6} style={{ display: "flex", alignItems: "center", gap: 5 }}>
						<ListItemAvatar>
							<Avatar>
								<Diversity3 />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={`Mentor Id: ${mentor.id}`} secondary={`Teaches ${mentor.teaches}`} />
					</Grid>
					<Grid item lg={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
						<ListItemText primary={`Name: ${mentor.name}`} secondary={`Joined on: ${moment(mentor.joined.toDate()).format("Do MMMM YYYY \\at hh:mm:ss")}`} />
					</Grid>
				</Grid>
			</ListItem>
			<Divider className={classes.divider} />
		</div>
	)
}

export default Mentors
