import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { Container, classes } from "styles/ContactUs";
import SendIcon from "@mui/icons-material/Send";
import Input from "./Input";
import { createMessage } from "context/dbActions/message";
import contact from "../assets/contact.jpg";

const regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ContactUs = ({ snackBar }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const initialError = { date: false, timeSlot: false, ageGroup: false };
  const [phone, setPhone] = useState(user?.phone);
  const [loading, setLoading] = useState(false);
  const initialState = {
    name: user ? user.name : "",
    email: user?.email ? `${user.email}` : "",
    phone: "",
    message: "",
  };
  const [error, setError] = useState(initialError);
  const [formData, setFormData] = useState(initialState);
  const validate = () => {
    const errors = {
      name: !formData.name.trim(),
      email: !formData.email || !formData.email.match(regex),
      phone: formData.phone.length < 13,
      message: formData.message.length < 12,
    };
    setError(errors);
    return !(errors.name || errors.email || errors.phone || errors.message);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setError(initialError);
    try {
      setLoading(true);
      await createMessage(formData);
    } catch (error) {
      console.log(error);
    }

    setFormData(initialState);
    setPhone("");
    snackBar("info", `Your message has been successfully sent.`);
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      return;
    }
    setPhone(e);
    setFormData({ ...formData, phone: e });
  };
  return (
    <Container className={classes.root}>
      <Container className={classes.header}>
        <Typography className={classes.heading}>CONTACT US</Typography>
        <Typography color="secondary" className={classes.subHeading}>
          If you have any questions, please use this form to contact us. One of
          our customer service representatives will get back to you as soon as
          possible.{" "}
        </Typography>
      </Container>
      <img
        className={classes.image}
        alt="background"
        src={contact}
        // src="https://images.unsplash.com/photo-1664809801650-de3ec7e76a08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
      />
      <Paper className={classes.form}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.label}>Name</Typography>
              <Input
                disabled={loading}
                name="name"
                onChange={handleChange}
                value={formData.name}
                placeholder="Enter your Name"
                error={error.name}
              />
              <Typography
                color="error"
                className={classes.errorLabel}
                style={{ display: error.name ? "block" : "none" }}
              >
                Please enter your name
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.label}>Email</Typography>
              <Input
                disabled={loading}
                name="email"
                placeholder="Enter your Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={error.email}
              />
              <Typography
                color="error"
                className={classes.errorLabel}
                style={{ display: error.email ? "block" : "none" }}
              >
                Please enter a valid email address
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.label}>Phone</Typography>
              <MuiTelInput
                disabled={loading}
                error={error.phone}
                defaultCountry="IN"
                name="phone"
                placeholder="Enter your Phone Number"
                onChange={handleChange}
                value={phone}
                fullWidth
                required
              />
              <Typography
                color="error"
                className={classes.errorLabel}
                style={{ display: error.phone ? "block" : "none" }}
              >
                Please enter a valid phone number
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.label}>Message</Typography>
              <Input
                disabled={loading}
                multiline
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your Message"
                rows="4"
                error={error.message}
              />
              <Typography
                color="error"
                className={classes.errorLabel}
                style={{ display: error.message ? "block" : "none" }}
              >
                Please enter your message
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="success"
                type="submit"
                variant="contained"
                style={{ fontSize: "15px" }}
                // endIcon={
                //   loading ? <CircularProgress size="0.5rem" /> : <SendIcon />
                // }
                fullWidth
                disabled={loading}
              >
                SEND
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ContactUs;
