import { styled } from "@mui/material/styles"
import MuiContainer from "@mui/material/Container"

const PREFIX = "Dashboard"

export const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	headerContainer: `${PREFIX}-headerContainer`,
	bodyContainer: `${PREFIX}-bodyContainer`,
	topGrid: `${PREFIX}-topGrid`,
	studentInfo: `${PREFIX}-studentInfo`,
	upcomingDemo: `${PREFIX}-upcomingDemo`,
	upcomingClasses: `${PREFIX}-upcomingClasses`,
	bottomGrid: `${PREFIX}-bottomGrid`,
	courses: `${PREFIX}-courses`,
	course: `${PREFIX}-course`,
	bookDemo: `${PREFIX}-bookDemo`,
	ageButton: `${PREFIX}-ageButton`,
	checkBox: `${PREFIX}-checkBox`,
	divider: `${PREFIX}-divider`,
	submit: `${PREFIX}-submit`,
	label: `${PREFIX}-label`,
}

export const Container = styled(MuiContainer)(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: 30,
	},
	[`& .${classes.paper}`]: {
		padding: 30,
	},
	[`& .${classes.headerContainer}`]: {
		marginTop: 20,
	},
	[`& .${classes.bookDemo}`]: {
		backgroundColor: " #FFB343",
		fontSize: 15,
		boxShadow: "4px 2.5px 2.5px #aeaeae",
		width: 300,
		margin: "10px auto",
		color: "white",
	},
	[`& .${classes.bodyContainer}`]: {
		marginTop: 40,
	},
	[`& .${classes.topGrid}`]: {
		width: "100%",
		justifyContent: "space-between",
	},
	[`& .${classes.studentInfo}`]: {
		backgroundColor: "#F8F26F",
		borderRadius: 20,
		padding: 20,
	},
	[`& .${classes.upcomingDemo}`]: {
		backgroundColor: "#B6E550",
		borderRadius: 20,
		padding: 20,
	},
	[`& .${classes.bottomGrid}`]: {
		width: "100%",
		justifyContent: "space-between",
		marginTop: 50,
	},
	[`& .${classes.courses}`]: {
		border: "1px solid grey",
		borderRadius: 20,
		padding: 20,
		gap: 20,
		display: "flex",
		flexDirection: "column",
	},
	[`& .${classes.course}`]: {
		height: 207,
		backgroundColor: "#c8c7c7",
	},
	[`& .${classes.upcomingClasses}`]: {
		backgroundColor: "#B6E550",
		borderRadius: 20,
		padding: 20,
		margin: 20,
	},
	[theme.breakpoints.down("sm")]: {
		[`& .${classes.bookDemo}`]: {
			width: "100%",
		},
		[`& .${classes.paper}`]: {
			padding: 15,
		},
		[`& .${classes.topGrid}`]: {
			gap: 30,
		},
		[`& .${classes.bottomGrid}`]: {
			gap: 30,
		},
		[`& .${classes.upcomingClasses}`]: {
			margin: "20px 0",
		},
		[`& .${classes.buttonGroup}`]: {
			justifyContent: "center",
		},
		[`& .${classes.timeButton}`]: {
			width: "100%",
		},
		[`& .${classes.ageButton}`]: {
			width: "100%",
		},
		[`& .${classes.gridItem}`]: {
			margin: "10px 0",
		},
	},
}))
