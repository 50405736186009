// CRUD FUNCTIONS FOR Messages in Firestore
import { doc, setDoc, collection, getDocs, deleteDoc } from "firebase/firestore"
import { db } from "../../firebase"

export const createMessage = async data => {
	const trimmedPhoneNo = data.phone.replace(/ /g, "")
	const documentRef = doc(collection(db, "contactMessages"))
	await setDoc(documentRef, { ...data, phone: trimmedPhoneNo })
}

export const fetchMessages = async () => {
	const messages = []
	const snap = await getDocs(collection(db, "contactMessages"))
	snap.forEach(doc => messages.push({ id: doc.id, ...doc.data() }))
	return messages
}

export const deleteMessage = async id => await deleteDoc(doc(db, "contactMessages", id))
