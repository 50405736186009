import { styled } from "@mui/material/styles";
import MuiContainer from "@mui/material/Container";

const PREFIX = "Enroll";

export const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  gridContainer: `${PREFIX}-gridContainer`,
  gridTitle: `${PREFIX}-gridTitle`,
  gridDetail: `${PREFIX}-gridDetail`,
  primaryButton: `${PREFIX}-primaryButton`,
};

export const Container = styled(MuiContainer)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f7fafd",
    // backgroundColor: "#FCF7FF",
    maxWidth: "100%",
    width: "100%",
    padding: "80px 140px",
    margin: "40px 0px",
  },
  [`& .${classes.heading}`]: {
    textShadow: "#929292 2px 2px 4px",
    fontSize: 42,
    color: "#000080",
  },
  [`& .${classes.gridContainer}`]: {
    alignItems: "stretch",
    justifyContent: "space-between",
    margin: "56px 0",
  },
  [`& .${classes.gridTitle}`]: {
    // color: "#ee4646",
    color: "#000080",
    fontWeight: "bold",
    fontSize: 22,
  },
  [`& .${classes.gridDetail}`]: {
    color: "#b9a9a9",
    fontSize: 18,
  },
  [`& .${classes.primaryButton}`]: {
    background: "#fcfe96",
    height: 43,
    boxShadow: "4px 4px 4px #BE9797",
    borderRadius: 40,
    color: "white",
    textTransform: "none",
    padding: "13px 40px",
  },
  [theme.breakpoints.down("md")]: {
    [`&.${classes.root}`]: {
      padding: 20,
      margin: "20px 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    [`&.${classes.root}`]: {
      padding: 25,
      margin: "20px 0",
    },
    [`& .${classes.heading}`]: {
      textAlign: "center",
      fontSize: 39,
    },
    [`& .${classes.gridTitle}`]: {
      fontSize: 28,
    },
    [`& .${classes.gridDetail}`]: {
      fontSize: 19,
    },
  },
}));
