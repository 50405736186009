import { styled } from "@mui/material/styles";
import MuiContainer from "@mui/material/Container";

const PREFIX = "Footer";

export const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  innerContainer: `${PREFIX}-innerContainer`,
  contactContainer: `${PREFIX}-contactContainer`,
  container: `${PREFIX}-container`,
  inputBox: `${PREFIX}-inputBox`,
  input: `${PREFIX}-input`,
  callButton: `${PREFIX}-callButton`,
  callButton2: `${PREFIX}-callButton2`,
  primaryButton: `${PREFIX}-primaryButton`,
  linksGroup: `${PREFIX}-linksGroup`,
  link: `${PREFIX}-link`,
  icon: `${PREFIX}-icon`,
  linkedin: `${PREFIX}-linkedin`,
  instagram: `${PREFIX}-instagram`,
  socialMedia: `${PREFIX}-socialMedia`,
};

export const Container = styled(MuiContainer)(({ theme }) => ({
  [`&.${classes.root}`]: {
    maxWidth: "100%",
    bottom: 0,
    minHeight: 400,
    backgroundColor: "#f7fafd",
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: 38,
    justifyContent: "space-between",
  },
  [`& .${classes.logo}`]: {
    fontWeight: 700,
    fontSize: 26,
    marginBottom: 5,
  },
  [`& .${classes.innerContainer}`]: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  [`& .${classes.contactContainer}`]: {
    gap: 26,
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.inputBox}`]: {
    backgroundColor: "white",
    height: 35,
    width: 400,
    borderRadius: 26,
    border: "none",
    boxShadow: "2px 2px 4px #c3c3c3",
    display: "flex",
    alignItems: "center",
    padding: 10,
    justifyContent: "space-between",
  },
  [`& .${classes.input}`]: {
    border: "none",
    margin: 12,
    width: 262,
    fontSize: 18,
  },
  [`& .${classes.callButton2}`]: {
    backgroundColor: "#2A2850",
    textTransform: "none",
    padding: "10px 20px",
    color: "white",
    borderRadius: 28,
    display: "none",
    fontSize: 19,
    height: 43,
  },
  [`& .${classes.callButton}`]: {
    backgroundColor: "#2A2850",
    textTransform: "none",
    padding: "10px 20px",
    color: "white",
    borderRadius: 28,
  },
  [`& .${classes.primaryButton}`]: {
    padding: "13px 18px",
    width: "70%",
  },
  [`& .${classes.container}`]: {
    gap: 18,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  [`& .${classes.linksGroup}`]: {
    display: "flex",
    flexDirection: "column",
    textDecoration: "none",
  },
  [`& .${classes.link}`]: {
    fontWeight: 500,
    textDecoration: "none",
    color: "black",
  },
  [`& .${classes.icon}`]: {
    fontSize: "50px",
  },
  [`& .${classes.linkedin}`]: {
    color: "#0e76a8",
    fontSize: "50px",
  },
  [`& .${classes.instagram}`]: {
    fontSize: "41px",
    color: "white",
    borderRadius: 3,
    background:
      "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
  },
  [theme.breakpoints.down("md")]: {
    [`& .${classes.innerContainer}`]: {
      flexDirection: "column",
      alignItems: "center",
    },
    [`& .${classes.primaryButton}`]: {
      width: 416,
      fontSize: 19,
    },
    [`&.${classes.root}`]: {
      position: "static",
    },
    [`& .${classes.linksGroup}`]: {
      alignItems: "center",
      marginBottom: 30,
    },
  },
  [theme.breakpoints.down("sm")]: {
    [`& .${classes.socialMedia}`]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    [`& .${classes.inputBox}`]: {
      width: "-webkit-fill-available",
    },
    [`& .${classes.input}`]: {
      width: "100%",
    },
    [`& .${classes.primaryButton}`]: {
      width: "100%",
    },
    [`& .${classes.callButton}`]: {
      display: "none",
    },
    [`& .${classes.callButton2}`]: {
      display: "flex",
      boxShadow: "4px 4px 4px #78778b",
    },
  },
}));
