// CRUD FUNCTIONS FOR Slots in Firestore
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"

export const fetchSlots = async () => {
	const documentRef = doc(db, "slots", 'slots')
	const snap = await getDoc(documentRef)
	return snap.data()
}

export const createSlot = async time => {
	const documentRef = doc(db, "slots", "slots")
	await updateDoc(documentRef, {
		[time]: true,
	})
}

export const deleteSlot = async time => {
	const documentRef = doc(db, "slots", "slots")
	await updateDoc(documentRef, {
		[time]: deleteField()
	})
}
