import { Grid } from "@mui/material";
import { classes, Box } from "styles/CoursesStyles";
import { CourseCard } from "./CourseCard";

export const TabPanel = ({
  value,
  title,
  index,
  dataArr,
  bookDemo = null,
  showFees = false,
  ...other
}) => {
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box className={classes.root}>
          <Grid
            container
            spacing={{
              md: dataArr.length < 3 ? 28 : 5,
              xs: 5,
            }}
          >
            {dataArr.map((value, key) => (
              <CourseCard
                value={value}
                title={title}
                showFees={showFees}
                key={key}
                bookDemo={bookDemo}
              />
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
};
