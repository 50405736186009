// CRUD FUNCTIONS FOR Demos in Firestore
import { updateDoc, doc, setDoc, serverTimestamp, getDoc, collection, getDocs, deleteDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { updateUser } from "./user"
import moment from "moment"
import { toggleSchedule } from "./schedule"

export const createDemo = async ({ firstName, lastName, course, email, date, timeSlot, phone, ageGroup, mentor }) => {
	const referenceNumber = generateRefNum()
	const documentRef = doc(db, "demos", `${referenceNumber}`)
	const slot = new Date(`${date} ${timeSlot}`)
	const payload = {
		name: `${firstName} ${lastName}`,
		phone,
		email,
		course,
		slot,
		meetingLink: "",
		mentor,
		completed: false,
		classReference: "",
		ageGroup,
		createdOn: serverTimestamp(),
	} // Demo Created

	await setDoc(documentRef, payload) // Demo Successfully Created
	await saveDemoInUser(phone, referenceNumber) // Demo referenceNumber stored in user
	await updateUser(phone, { email })
	return referenceNumber
}

export const fetchDemos = async () => {
	const result = []
	const snap = await getDocs(collection(db, "demos"))
	snap.forEach(demo => result.push({ id: demo.id, ...demo.data() }))
	return result
}

export const fetchDemosByUser = async phone => {
	const result = []
	const demos = await fetchDemos()

	demos.forEach(demo => {
		if (demo.phone === phone) result.push(demo)
	})
	return result
}

export const updateDemo = async (id, data) => {
	const documentRef = doc(db, "demos", id)
	delete data.id
	await updateDoc(documentRef, data)
	if (data.completed) {
		const snap = await getDoc(documentRef)
		const { mentor, slot } = snap.data()
		const dateTime = moment(slot.toDate())
		const time = dateTime.format("hh:mmA").replace(/^0+/, "")
		toggleSchedule(mentor, dateTime.format("dddd"), time)
	}
}


const saveDemoInUser = async (phone, referenceNumber) => {
	const trimmedPhoneNo = phone.replace(/ /g, "")
	const documentRef = doc(db, "users", trimmedPhoneNo)
	const snap = await getDoc(documentRef)
	if (!snap.exists()) {
		return console.error("user Not found")
	}
	const user = snap.data()

	const payload = {
		demoReferences: [...user.demoReferences, referenceNumber],
	}
	await updateDoc(documentRef, payload)
}

export const deleteDemo = async referenceId => {
	const documentRef = doc(db, "demos", referenceId)

	const snap = await getDoc(documentRef)
	const userPhone = snap.data().phone
	const { mentor, slot } = snap.data()
	const dateTime = moment(slot.toDate())
	const time = dateTime.format("hh:mmA").replace(/^0+/, "")
	toggleSchedule(mentor, dateTime.format("dddd"), time)
	await deleteDoc(doc(db, "demos", referenceId))
	await deleteDemoFromUser(userPhone, referenceId)
}

const deleteDemoFromUser = async (phone, referenceId) => {
	const documentRef = doc(db, "users", phone)
	const snap = await getDoc(documentRef)
	const user = snap.data()
	const payload = {
		demoReferences: user.demoReferences.filter(ref => ref.toString() !== referenceId),
	}
	await updateDoc(documentRef, payload)
}

// Miscellaneous Utility Functions
export const generateRefNum = () => {
	const min = 1_00_00_000
	const max = 9_99_99_999

	return Math.floor(Math.random() * (max - min) + min)
}

export const fetchDemo = async demoRef => {
	const documentRef = doc(db, "demos", demoRef)
	const snap = await getDoc(documentRef)

	return { ...snap.data(), id: snap.id }
}
