import { Navigate } from "react-router-dom"
import { useAuth } from "../context/AuthContext"

const AdminRoute = ({ component }) => {
	const { currentUser } = useAuth()

	return !currentUser || currentUser.phone ? <Navigate to="/" /> : component
}

export default AdminRoute
