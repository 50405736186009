import { styled } from "@mui/material/styles";
import MuiContainer from "@mui/material/Container";

const PREFIX = "BookDemo";

export const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  form: `${PREFIX}-form`,
  gridItem: `${PREFIX}-gridItem`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  dateButton: `${PREFIX}-dateButton`,
  timeButton: `${PREFIX}-timeButton`,
  telInput1: `${PREFIX}-telInput1`,
  telInput2: `${PREFIX}-telInput2`,
  ageButton: `${PREFIX}-ageButton`,
  checkBox: `${PREFIX}-checkBox`,
  divider: `${PREFIX}-divider`,
  tabBox: `${PREFIX}-tabBox`,
  submit: `${PREFIX}-submit`,
  label: `${PREFIX}-label`,
};

export const Container = styled(MuiContainer)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: 30,
  },
  [`& .${classes.paper}`]: {
    padding: 30,
    margin: 45,
  },
  [`& .${classes.tabBox}`]: {
    borderBottom: 1,
    borderColor: "divider",
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  [`& .${classes.form}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [`& .${classes.label}`]: {
    width: "100%",
    margin: "4px 0px 0px 0px",
    maxWidth: "100%",
  },
  [`& .${classes.buttonGroup}`]: {
    // width: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: 9,
  },
  [`& .${classes.dateButton}`]: {
    padding: "7px 0px",
    width: 99,
    height: 124,
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.ageButton}`]: {
    borderRadius: "inherit",
    borderColor: "inherit",
    padding: "7px 24px",
    width: "127px",
  },
  [`& .${classes.timeButton}`]: {
    padding: "7px 24px",
    width: "127px",
  },
  [`& .${classes.checkBox}`]: {
    alignSelf: "flex-start",
  },
  [`& .${classes.divider}`]: {
    borderColor: "black",
    width: "60%",
  },
  [`& .${classes.submit}`]: {
    maxWidth: "300px",
    margin: 15,
  },
  [`& .${classes.telInput2}`]: {
    display: "none",
  },
  [theme.breakpoints.down("lg")]: {
    [`& .${classes.gridItem}`]: {
      width: "100%",
      margin: "60px 0 5px",
    },
    [`& .${classes.telInput1}`]: {
      display: "none",
    },
    [`& .${classes.telInput2}`]: {
      display: "block",
    },
    [`& .${classes.label}`]: {
      margin: "6px 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    [`&.${classes.root}`]: {
      padding: 10,
    },
    [`& .${classes.dateButton}`]: {
      width: "100%",
    },
    [`& .${classes.buttonGroup}`]: {
      justifyContent: "center",
    },
    [`& .${classes.timeButton}`]: {
      width: "100%",
    },
    [`& .${classes.ageButton}`]: {
      width: "100%",
    },
    [`& .${classes.gridItem}`]: {
      margin: "10px 0",
    },
  },
}));
