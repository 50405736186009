import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import AuthProvider from "context/AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "components/Navbar";
import SnackBar from "components/SnackBar";
import Home from "components/Home";
import BookDemo from "components/BookDemo";
import SuccessPage from "components/SuccessPage";
import Dashboard from "components/Dashboard";
import PrivateRoute from "components/PrivateRoute";
import Footer from "components/Footer";
import ContactUs from "components/ContactUs";
import BookClass from "components/BookClass";
import {
  Auth as AdminAuth,
  Dashboard as AdminDashboard,
  Sidebar,
  Users,
  Mentors,
  Courses,
  Messages,
  Demos,
  Schedules,
  Classes,
  Slots,
} from "components/Admin";
import "./App.css";
import AdminRoute from "components/AdminRoute";
import Auth from "components/Auth";
import Login from "components/Login";
import PaymentGateway from "components/PaymentGateway";
import Terms from "components/Home/Terms";
import Pricing from "components/Home/Pricing";
import Privacy from "components/Privacy";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"].join(","),
  },
  palette: {
    primary: { main: "#4dabf5" },
    secondary: { main: "#b4b4b4" },
  },
});

const App = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const [openBookDemo, setOpenBookDemo] = useState(false);
  const bookDemo = (navigate) =>
    localStorage.getItem("user")
      ? navigate("/book-demo")
      : setOpenBookDemo(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = (severity, message) => {
    setAlertSeverity(severity);
    setSnackBarMessage(message);
    setOpenSnackbar(true);
  };
  const snackBarprops = {
    open: openSnackbar,
    setOpen: setOpenSnackbar,
    alertSeverity,
    snackBarMessage,
  };
  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <div className="App">
            <Navbar bookDemo={bookDemo} openLogin={setOpenLogin} />
            <SnackBar {...snackBarprops} />
            <Sidebar />
            <Auth setOpen={setOpenBookDemo} open={openBookDemo} />
            <Login setOpen={setOpenLogin} open={openLogin} />
            <Routes>
              <Route
                path="/"
                element={<Home bookDemo={bookDemo} openLogin={setOpenLogin} />}
              />
              <Route
                path="/contact-us"
                element={<ContactUs snackBar={openSnackBar} />}
              />
              <Route
                path="/terms"
                element={<Terms snackBar={openSnackBar} />}
              />
              <Route
                path="/privacypolicy"
                element={<Privacy snackBar={openSnackBar} />}
              />
              <Route
                path="/courses-pricing"
                element={<Pricing snackBar={openSnackBar} />}
              />
              <Route
                path="/book-demo"
                element={
                  <PrivateRoute
                    component={<BookDemo snackBar={openSnackBar} />}
                  />
                }
              />
              <Route
                path="/success-page"
                element={<PrivateRoute component={<SuccessPage />} />}
              />
              <Route
                path="/dashboard"
                element={<PrivateRoute component={<Dashboard />} />}
              />
              <Route
                path="/book-class"
                element={
                  <PrivateRoute
                    component={<BookClass snackBar={openSnackBar} />}
                  />
                }
              />
              <Route path="/payment-gateway" element={<PaymentGateway />} />
              {/* Admin Routes */}
              <Route
                path="/admin"
                element={<AdminAuth snackBar={openSnackBar} />}
              />
              <Route
                path="/admin/dashboard"
                element={<AdminRoute component={<AdminDashboard />} />}
              />
              <Route
                path="/admin/dashboard/users"
                element={<AdminRoute component={<Users />} />}
              />
              <Route
                path="/admin/dashboard/mentors"
                element={<AdminRoute component={<Mentors />} />}
              />
              <Route
                path="/admin/dashboard/courses"
                element={<AdminRoute component={<Courses />} />}
              />
              <Route
                path="/admin/dashboard/messages"
                element={<AdminRoute component={<Messages />} />}
              />
              <Route
                path="/admin/dashboard/demos"
                element={<AdminRoute component={<Demos />} />}
              />
              <Route
                path="/admin/dashboard/Schedules"
                element={<AdminRoute component={<Schedules />} />}
              />
              <Route
                path="/admin/dashboard/Classes"
                element={<AdminRoute component={<Classes />} />}
              />
              <Route
                path="/admin/dashboard/Slots"
                element={<AdminRoute component={<Slots />} />}
              />
            </Routes>
            <Footer dialogBox={setOpenBookDemo} bookDemo={bookDemo} />
          </div>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
