import { Avatar, Typography } from "@mui/material";
import { Container, classes } from "styles/ChooseUsStyles";
import { LibraryAddCheckOutlined, QuestionMark } from "@mui/icons-material";

const ChooseUs = () => {
  return (
    <Container className={classes.root} id="why-ehobby">
      <div className={classes.header}>
        <div style={{ display: "flex", gap: 10 }}>
          <Typography className={classes.text}>Why choose ehobby?</Typography>
          <Avatar className={classes.question}>
            <QuestionMark className={classes.icon} />
          </Avatar>
        </div>
        <Typography variant="h5" className={classes.subHeader}>
          What makes us stand out from other choices?
        </Typography>
      </div>
      <div className={classes.body}>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            Access 24/7 customer care support.{" "}
          </Typography>
        </div>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            Choose your preferred slots and easily reschedule classes.
          </Typography>
        </div>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            Experience continuity with the same teacher in both demo and regular
            sessions.{" "}
          </Typography>
        </div>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            Opt for our monthly enrollment renewal system, avoiding upfront
            payments for 3 or 6 months.
          </Typography>
        </div>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            Utilize our Learning Management System (LMS) portal, offering access
            to notes, worksheets, and tests for each enrolled student.
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default ChooseUs;
